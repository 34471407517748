import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Tasks from "components/Tasks/Tasks.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Danger from "components/Typography/Danger.jsx";
import Info from "components/Typography/Info.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "components/CustomButtons/Button.jsx";
import { bugs, website, server } from "variables/general.jsx";
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import beautifyDate from '../variables/date.js'


class MyProjectGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      memberNames: "",
      insFeedback:"",
      refreshPage: false
    };
  }

  processMemberInfo(){
    var memberObject = ''
    if(this.props.projectInfo.members.length != 0)
      memberObject = JSON.parse(this.props.projectInfo.members)
    else
      memberObject = []
    var names = [];
    memberObject.map(member => (
        names.push(member.name)
      ))

    names.sort()

    var nameString = ""
    names.map(member => (
        nameString += member.replace(/\,/g, '')+' / '
      ))

    this.setState({
      memberNames: nameString
    })
    return memberObject
  }

  generateInsFeedback(memberList){
    var projectInfo = this.props.projectInfo
    if(projectInfo.ins_feedback == null)
      this.setState({
        insFeedback:"Feedback from instructor is unavailable right now"
      })
    else if(projectInfo.courseInfo.ins_fb_mode == "group"){
      console.log("group ins mode", projectInfo)
      if(projectInfo.ratingFinished){
        this.setState({
          insFeedback:this.props.projectInfo.ins_feedback
        })
      }
      else{
        var insFeedback="The instructor's feedback will be shown when every group member finishes rating all the feedback. Please remind "
        for(let i=0; i<memberList.length; i++){
          if(memberList[i].numUnrateFeedback != 0)
            insFeedback += memberList[i].name + " ("+ memberList[i].numUnrateFeedback+" unrated feedback) "
        }
        insFeedback += "to complete rating the feedback."
        this.setState({
          insFeedback:insFeedback
        })
      }
    }
    else if(projectInfo.courseInfo.ins_fb_mode == "me"){
      if(this.props.iHaveRatedAll)
        this.setState({
          insFeedback:this.props.projectInfo.ins_feedback
        })
      else{
        var insFeedback = "The instructor's feedback will be shown after you have given and rated all feedbacks."
        this.setState({
          insFeedback:insFeedback
        })
      }
    }
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  componentDidMount(){
    var memberObject = this.processMemberInfo();
    this.generateInsFeedback(memberObject);
  }

  componentDidUpdate(prevProps){
    if (this.props.iHaveRatedAll !== prevProps.iHaveRatedAll) {
      var memberObject = this.processMemberInfo();
      this.generateInsFeedback(memberObject);
    }
  }

  handleChangeIndex = index => {
    this.setState({ value: index });

  };


  render() {
    const { classes } = this.props;
    const projectInfo = this.props.projectInfo

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite}>{projectInfo.project_name}</h4>
                <p className={classes.cardCategoryWhite}>
                  {projectInfo.group_name}&emsp;&emsp;<span hidden={this.props.projectInfo.courseInfo.show_presen_date == 0}>Presentation Date: {beautifyDate(projectInfo.presen_date)}</span>
                </p>
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardCategory}>Group Members</h4>
                <p className={classes.description}>
                  {this.state.memberNames}
                </p>
                <h4 className={classes.cardCategory}>Project Description</h4>
                <p className={classes.description}>
                  {projectInfo.project_description}
                </p>
                <h4 className={classes.cardCategory}>Instructor Feedback (*This part is updated everytime when you login)</h4>
                <p className={classes.description}>
                  {this.state.insFeedback}
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}


MyProjectGrid.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(MyProjectGrid);
