import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import endpoint from '../../variables/endPoint.js'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import Modal from '@material-ui/core/Modal';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Danger from "components/Typography/Danger.jsx";
import Papa from 'papaparse';
import { SnackbarProvider, withSnackbar } from 'notistack';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);



const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
   paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  section1: {
    display: "flex",
    flexDirection: "row"
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  semBtns:{
    display: "flex",
    flexDirection: "row"
      }
});


function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];


class CustomizedTables extends React.Component {

  constructor(props){
    super(props)
    this.state={
      statsIsReady: true,
      openImportBox: false,
      openDeleteBox: false,
      openCourseBox: false,
      openRefreshBox: false,
      filesInvalid: [true, true],
      isLoading: false,
      fileUploadMsg: "",
      idCourseToImport: -1,
      idCourseToDelete: -1,
      idCourseToRefresh: -1,
      sectionCourseToImport: "",
      userDataToImport: [],
      groupDataToImport: [],
      courseToDelete: {},
      courseToRefresh: {},
      courseToEdit: {"fb_form":{"num_questions": 0, "questions":[]}},
      courseToEditNew: {},
      formMsg: "",
      formQuestions:[],
      updateCourseMsg:""
    }
    this.downloadFile = this.downloadFile.bind(this)
    this.openImportBox = this.openImportBox.bind(this)
    this.checkFileValidity = this.checkFileValidity.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.handleCourseInputChange = this.handleCourseInputChange.bind(this)
  }

  downloadFile(courseInfo){
    this.setState({
      statsIsReady:false
    })

    fetch( endpoint +"/admin/getCourseStats", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        course_id: courseInfo.course_id
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
          this.setState({
            statsIsReady: true
          })
        } else {
           console.log(res.data.stu_stats)
          let csvContent_stu = "data:text/csv;charset=utf-8,"+res.data.stu_stats.replace(/\\/g, '\"');
          csvContent_stu = csvContent_stu.replace(/#/g, "");
          //console.log(csvContent_stu)
          var encodedUri_stu = encodeURI(csvContent_stu);
          var link_stu = document.createElement("a");
          link_stu.setAttribute("href", encodedUri_stu);
          link_stu.setAttribute("download", courseInfo.course_code+courseInfo.section+"stu_stats.csv");
          document.body.appendChild(link_stu); // Required for FF
          link_stu.click(); // This will download the data file named "my_data.csv".

          let csvContent_group = "data:text/csv;charset=utf-8,"+res.data.group_stats
          var encodedUri_group = encodeURI(csvContent_group);
          var link_group = document.createElement("a");
          link_group.setAttribute("href", encodedUri_group);
          link_group.setAttribute("download", courseInfo.course_code+courseInfo.section+"group_stats.csv");
          document.body.appendChild(link_group); // Required for FF
          link_group.click(); // This will download the data file named "my_data.csv".
        }
      });
  }

  checkFileValidity(type, data){
    console.log(type, data)
    if(type == "user"){
      var errorField = ""
      if(data[0]["last_name"] == undefined)
        errorField = "last_name"
      else if(data[0]["first_name"] == undefined)
        errorField = "first_name"
      else if(data[0]["email"] == undefined)
        errorField = "email"
      else if(data[0]["group_name"] == undefined)
        errorField = "group_name"

      if(errorField == ""){
        this.setState({
          fileUploadMsg: "",
          filesInvalid: [false, this.state.filesInvalid[1]],
          userDataToImport: data
        })
      }
      else
        this.setState({
          fileUploadMsg: "student data required field: "+errorField
        })
      
    }
    else if(type == "group"){
      var errorField = ""
      if(data[0]["group_name"] == undefined)
        errorField = "group_name"
      else if(data[0]["presen_date"] == undefined)
        errorField = "presen_date"
      else if(data[0]["project_name"] == undefined)
        errorField = "project_name"
      else if(data[0]["project_descr"] == undefined)
        errorField = "project_descr"

      for(let i = 0; i < data.length; i++){
        data[i]["presen_date"] = this.convertPresenDate(data[i]["presen_date"])
      }

      if(errorField == ""){
        this.setState({
          fileUploadMsg: "",
          filesInvalid: [this.state.filesInvalid[0], false],
          groupDataToImport: data
        })
      }
      else
        this.setState({
          fileUploadMsg: "project group data required field: "+errorField
        })
    }
  }

  convertPresenDate(date){
    //dd-mm-yyyy -> mm-dd-yyyy
    var components = date.split("-")
    return components[1]+"-"+components[0]+"-"+components[2]
  }

  onFileChange(e){
    const file = e.target.files[0]
    const fileType = e.target.id
    if(file.name.substring(file.name.length-4,) != ".csv"){
      this.setState({
        fileUploadMsg: "Invalid file format. Please upload .csv file."
      })
      return
    }
    var checkValFunction = this.checkFileValidity
    var reader = new FileReader()
    reader.onload = function () {
          var data = Papa.parse(reader.result, {"header":true});
          checkValFunction(fileType, data["data"])
        };
    reader.readAsBinaryString(file);
  }

 

  openImportBox(courseInfo){
   // var info = e.target.id.split(",")
    this.setState({
      openImportBox: true,
      idCourseToImport: courseInfo.course_id,
      sectionCourseToImport: courseInfo.section
    })
  }

  openDeleteBox(courseInfo){
    console.log("openDeleteBox: ", courseInfo)
    this.setState({
      openDeleteBox: true,
      idCourseToDelete: courseInfo.course_id,
      courseToDelete: courseInfo
    })
  }

  openRefreshBox(courseInfo){
    this.setState({
      openRefreshBox: true,
      idCourseToRefresh: courseInfo.course_id,
      courseToRefresh: courseInfo
    })
  }

  openCourseBox(courseInfo){
    console.log("openCourseBox: ", courseInfo)
    var infoString = JSON.stringify(courseInfo)
    this.setState({
      openCourseBox: true,
      courseToEdit: JSON.parse(infoString),
      formQuestions: JSON.parse(JSON.parse(infoString)["fb_form"])["questions"]
    })
  }

  handleImportData(val){
    if(val == 0){
      this.setState({
        filesInvalid: [true, true]
      })
    }
    else{
      //import data
      fetch( endpoint +"/admin/importCourseData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        courseInfo:{
          "course_id": this.state.idCourseToImport,
          "section": this.state.sectionCourseToImport,
          "group_info": this.state.groupDataToImport,
          "user_info":this.state.userDataToImport
        }
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
         console.log(res.err)
        this.props.enqueueSnackbar('Fail to import data', { variant: 'warning' })
        } else {
          this.props.enqueueSnackbar('Data is imported!', { variant: 'success' });
          this.props.updateCourseInfo()
          console.log("table call: updateCourseInfo")
        }
      });
    }
    this.setState({openImportBox: false})

  }

  handleDeleteData(val){
    if(val == 0){
 
    }
    else{
      //import data
      console.log("delete course: ", this.state.courseToDelete)
      fetch( endpoint +"/admin/deleteCourse", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
          "course_id": this.state.idCourseToDelete
        
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
         console.log(res.err)
        this.props.enqueueSnackbar('Fail to delete course', { variant: 'warning' })
        } else {
          this.props.enqueueSnackbar('Course is deleted!', { variant: 'success' });
          this.props.updateCourseInfo()
        }
      });
    }
    this.setState({openDeleteBox: false})

  }

  handleRefreshData(val){
    if(val == 0){
 
    }
    else{
      //import data
      fetch( endpoint +"/admin/refreshPwd", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
          "course_id": this.state.idCourseToRefresh
        
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
         console.log(res.err)
        this.props.enqueueSnackbar('Fail to refresh', { variant: 'warning' })
        } else {
          this.props.enqueueSnackbar('Refreshed!', { variant: 'success' });
          this.props.updateCourseInfo()
        }
      });
    }
    this.setState({openRefreshBox: false})

  }

  checkNewCourseInfoValidity(){
    var courseMsg = ""
    var newCourseInfo = this.state.courseToEdit

    if(newCourseInfo["course_code"] == undefined || newCourseInfo["course_code"] == "")
      courseMsg = "Course code cannot be empty"
    else if(newCourseInfo["section"] == undefined || newCourseInfo["section"] == "")
      courseMsg = "Section cannot be empty"
    else if(newCourseInfo["course_name"] == undefined || newCourseInfo["course_name"] == "")
      courseMsg = "Course name cannot be empty"
    else if(newCourseInfo["fb_form"] == undefined || newCourseInfo["fb_form"] == "")
      courseMsg = "Course form cannot be empty"
    else if(newCourseInfo["ins_fb_mode"] == undefined)
      courseMsg = "Instructor feedback mode cannot be empty"
    else if(newCourseInfo["rate_fb_qindex"] == undefined){
      console.log("here you go: ", newCourseInfo["rate_fb_qindex"])
      courseMsg = "Question for peer evaluation cannot be empty"
    }
     else if(newCourseInfo["cal_gp_avg_qindex"] == undefined)
      courseMsg = "Question for group average score calculation cannot be empty"
     
    this.setState({
      createCourseMsg: courseMsg
    })

    if(courseMsg != "")
      return false
    return true
  }


  handleEditCourseInfo(state){
    console.log("newCourseInfo: ",this.state.courseToEditNew)
    console.log("oldCourseInfo: ",this.state.courseToEdit)

    if(state == 0)
      this.setState({openCourseBox: false})
    else if(state == 1){
      if(this.checkNewCourseInfoValidity()){

        var courseInfo = this.state.courseToEdit
        //fetch
        fetch( endpoint +"/admin/updateCourseMetaInfo", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            new_course_info: this.state.courseToEdit
          })
        })
          .then(res => res.json())
          .then(res => {
            if (res.err) {
              this.props.enqueueSnackbar('Fail to update course info', { variant: 'warning' });
            } else {
              this.props.enqueueSnackbar('Course info is updated!', { variant: 'success' });
              this.props.updateCourseInfo()
              this.setState({openCourseBox: false})
            }
          });
      }
     // this.setState({openCourse: false})
    }
  }

  checkFormValidity(form){
       try {
          var form = JSON.parse(form)
          console.log(form)
          this.setState({
            formMsg: "",
            formQuestions: form["questions"]
          })
          } catch (err) {
          this.setState({
            formMsg: "invalid input form",
            formQuestions: []
          })
        }
    }

  handleCourseInputChange(e){
    var field = e.target.name
    console.log(field, e.target.value)
    var prevCourseInfo = this.state.courseToEdit

    switch(field){
      case "courseName":
        prevCourseInfo["course_name"] = e.target.value
        break;
      case "courseCode":
        prevCourseInfo["course_code"] = e.target.value
        break;
      case "section":
        prevCourseInfo["section"] = e.target.value
        break;
      case "courseForm":
        prevCourseInfo["fb_form"] = e.target.value
        this.checkFormValidity(e.target.value)
        break;
      case "rateFBIndex":
        prevCourseInfo["rate_fb_qindex"] = e.target.value
        break;
      case "groupAvgIndex":
        prevCourseInfo["cal_gp_avg_qindex"] = e.target.value
        break;
      case "insFBMode":
        prevCourseInfo["ins_fb_mode"] = e.target.value
        break;
      case "fbDDL":
        prevCourseInfo["fb_ddl"] = e.target.value
        break;
      case "showPresenDate":
        prevCourseInfo["show_presen_date"] = e.target.value
    }
 
    this.setState({
      courseToEdit: prevCourseInfo
    })

  }


  render(){
      const { classes } = this.props;
      var rows = []
      if(this.props.courseInfo!=undefined)
        rows = this.props.courseInfo

    const bodyImport = (
        <div style={{backgroundColor:"white"}}>
          <div className={classes.paper}>
              <div style={{'fontSize': '12px'}}>student record(csv file is required)</div>
              <input id="user" type="file" onChange={this.onFileChange} /> 
              <div style={{'fontSize': '12px',marginTop:"20px"}}>project group record(csv file is required)</div>
              <input id="group" type="file" onChange={this.onFileChange}/> 

              <Danger>{this.state.fileUploadMsg}</Danger>
              <div className={classes.semBtns}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submit}
                  onClick={() => this.handleImportData(0)}
                  disabled={this.state.isLoading}
                  style={{marginRight:"10%"}}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => this.handleImportData(1)}
                  disabled={this.state.isLoading || this.state.filesInvalid[0] || this.state.filesInvalid[1]}
                >
                  Create
                </Button>
              </div>
          </div>
        </div>
      );

    const bodyDelete = (
        <div style={{backgroundColor:"white"}}>
          <div className={classes.paper}>
              <div style={{'fontSize': '16px'}}>Are you sure you want to delete {this.state.courseToDelete["course_code"]+" "+this.state.courseToDelete["section"]}?</div>   
              <div className={classes.semBtns}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submit}
                  onClick={() => this.handleDeleteData(0)}
                  disabled={this.state.isLoading}
                  style={{marginRight:"10%"}}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => this.handleDeleteData(1)}
                  disabled={this.state.isLoading}
                >
                  Delete
                </Button>
              </div>
          </div>
        </div>
      );

        const bodyRefresh = (
        <div style={{backgroundColor:"white"}}>
          <div className={classes.paper}>
              <div style={{'fontSize': '16px'}}>Are you sure you want to refresh users' password in {this.state.courseToRefresh["course_code"]+" "+this.state.courseToRefresh["section"]}?</div>   
              <div className={classes.semBtns}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submit}
                  onClick={() => this.handleRefreshData(0)}
                  disabled={this.state.isLoading}
                  style={{marginRight:"10%"}}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => this.handleRefreshData(1)}
                  disabled={this.state.isLoading}
                >
                  Refresh
                </Button>
              </div>
          </div>
        </div>
      );

     const bodyCourse = (
        <div style={{backgroundColor:"white"}}>
          <div className={classes.paper}>
              <div className={classes.section1}>
                <FormControl margin="normal" required fullWidth style={{marginRight:"10%"}}>
                  <InputLabel htmlFor="username">Course Code</InputLabel>
                  <Input id="courseCode" name="courseCode" onChange={this.handleCourseInputChange} defaultValue={this.state.courseToEdit["course_code"]} autoFocus />
                </FormControl>
                <FormControl margin="normal" required fullWidth style={{marginRight:"10%"}}>
                  <InputLabel htmlFor="username">Section</InputLabel>
                  <Input id="section" name="section" onChange={this.handleCourseInputChange}  defaultValue={this.state.courseToEdit["section"]}/>
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="username">Course Name</InputLabel>
                  <Input id="courseName" name="courseName" onChange={this.handleCourseInputChange}  defaultValue={this.state.courseToEdit["course_name"]}/>
                </FormControl>
              </div>
              <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="username">Presentation Evaluation Form</InputLabel>
                  <Input id="courseForm" name="courseForm" onChange={this.handleCourseInputChange}  defaultValue={this.state.courseToEdit["fb_form"]}/>
              </FormControl>
              <Danger>{this.state.formMsg}</Danger>
              <FormControl margin="normal" required fullWidth>
                Question for Presentation Evaluation
                <Select
                  value={this.state.courseToEdit["rate_fb_qindex"]}
                  onChange={this.handleCourseInputChange}
                  displayEmpty
                  name="rateFBIndex"
                  style={{marginBottom: "20px"}}
                  id="rateFBIndex"

                >
                  {this.state.formQuestions.map((value, index) => {
                    return <MenuItem value={index}>{value["description"]}</MenuItem>
                  })}
                </Select>
                Question for Presentation Scoring
                <Select
                  id="groupAvgIndex"
                  value={this.state.courseToEdit["cal_gp_avg_qindex"]}
                  onChange={this.handleCourseInputChange}
                  displayEmpty
                  name="groupAvgIndex"
                  style={{marginBottom: "20px"}}
                  
                >
                  {this.state.formQuestions.map((value, index) => {
                    return <MenuItem value={index}>{value["description"]}</MenuItem>
                  })}
                </Select>
                 Show Instructor's Feedback when
                <Select
                  value={this.state.courseToEdit["ins_fb_mode"]}
                  onChange={this.handleCourseInputChange}
                  displayEmpty
                  name="insFBMode"
                  style={{marginBottom: "20px"}}
                >
                  <MenuItem value="me">The student has given and rated all feedbacks</MenuItem>
                  <MenuItem value="group">The student and all group members have rated all feedbacks</MenuItem>
                </Select>
                Deadline to Submit Presentation Evaluation
                <Select
                  value={this.state.courseToEdit["fb_ddl"]}
                  onChange={this.handleCourseInputChange}
                  displayEmpty
                  name="fbDDL"
                  style={{marginBottom: "20px"}}
                >
                  <MenuItem value="0">+0 day (same day of the group's presentation)</MenuItem>
                  <MenuItem value="1">+1 day</MenuItem>
                  <MenuItem value="2">+2 days</MenuItem>
                  <MenuItem value="3">+3 days</MenuItem>
                  <MenuItem value="4">+4 days</MenuItem>
                </Select>
                Show Presentation Date in Student Console
                <Select
                  value={this.state.courseToEdit["show_presen_date"]}
                  onChange={this.handleCourseInputChange}
                  displayEmpty
                  name="showPresenDate"
                  style={{marginBottom: "20px"}}
                >
                  <MenuItem value="1">Yes</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </Select>
              </FormControl>
              <Danger>{this.state.updateCourseMsg}</Danger>
              <div className={classes.semBtns}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submit}
                  onClick={() => this.handleEditCourseInfo(0)}
                 disabled={this.state.isLoading}
                 style={{marginRight:"10%"}}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => this.handleEditCourseInfo(1)}
                 disabled={this.state.isLoading}
                >
                  Update
                </Button>
              </div>
          </div>
        </div>
      );

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Course Code</StyledTableCell>
              <StyledTableCell align="right">Section</StyledTableCell>
              <StyledTableCell align="right">#Students</StyledTableCell>
              <StyledTableCell align="right">#Groups</StyledTableCell>
              <StyledTableCell align="right">Ins Feedback Status </StyledTableCell>
              <StyledTableCell align="right">Operations </StyledTableCell>
              <StyledTableCell align="right">Summary Statistics </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <StyledTableRow key={row.course_code+row.section}>
                <StyledTableCell component="th" scope="row">
                  {row.course_code}
                </StyledTableCell>
                <StyledTableCell align="right">{row.section}</StyledTableCell>
                <StyledTableCell align="right">{row.total_stu}</StyledTableCell>
                <StyledTableCell align="right">{row.total_groups}</StyledTableCell>
                <StyledTableCell align="right">{row.ins_fb_given}/{row.total_groups}</StyledTableCell>
                <StyledTableCell align="right" id={row.course_id+","+row.section}>
                  <IconButton aria-label="import" className={classes.margin} onClick={() => this.openImportBox(row)}>
                   <AddCircleIcon/>
                  </IconButton>
                  <IconButton aria-label="edit" className={classes.margin} onClick={() => this.openCourseBox(row)}>
                   <EditIcon/>
                  </IconButton>
                  <IconButton aria-label="delete" className={classes.margin} onClick={() => this.openDeleteBox(row)}>
                    <DeleteIcon/>
                  </IconButton>
                  <IconButton aria-label="delete" className={classes.margin} onClick={() => this.openRefreshBox(row)}>
                    <RefreshIcon/>
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell align="right"><Button onClick={()=>this.downloadFile(row)}>Download</Button></StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <Modal
            open={this.state.openImportBox}
            onClose={this.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{top:"20%", left:"50%", width:"300px"}} 
          >
            {bodyImport}
          </Modal>

          <Modal
            open={this.state.openDeleteBox}
            onClose={this.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{top:"20%", left:"50%", width:"300px"}} 
          >
            {bodyDelete}
          </Modal>
          <Modal
            open={this.state.openCourseBox}
            onClose={this.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{top:"5%", left:"30%", width:"600px"}} 
          >
            {bodyCourse}
          </Modal>
          <Modal
            open={this.state.openRefreshBox}
            onClose={this.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{top:"20%", left:"50%", width:"300px"}} 
          >
            {bodyRefresh}
          </Modal>
      </Paper>
    );
  }
}

const ins = withSnackbar(CustomizedTables);
export default withStyles(styles)(ins);
