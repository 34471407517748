import React from 'react';
import { withStyles, makeStyles, useTheme,createMuiTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Select from '@material-ui/core/Select';
import { Theme } from '@material-ui/core/styles/createMuiTheme';


const options = [
  'Show some love to Material-UI',
  'Show all notification content',
  'Hide sensitive notification content',
  'Hide all notification content',
];



const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

class CourseList extends React.Component{
  constructor(props){
    super(props)
    var newCurCourseId = ""
    if(this.props.courseInfo.length == 0)
      newCurCourseId = -1
    else
      newCurCourseId = this.props.courseInfo[0].course_id

    this.state={
      anchorEl: null,
      selectedIndex: 0,
      curCourseId: newCurCourseId
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleClickListItem(event) {
    this.setState({anchorEl:event.currentTarget})
  }

  componentDidUpdate(prevProps){
    if(JSON.stringify(prevProps.courseInfo) != JSON.stringify(this.props.courseInfo)){
      var newCourseID = -1
      if(this.props.courseInfo != undefined && this.props.courseInfo.length != 0)
        newCourseID = this.props.courseInfo[0].course_id
      this.setState({
        curCourseId: newCourseID
      })
    }
  }

  handleMenuItemClick(event, index) {
    this.setState({selectedIndex:index, anchorEl:null})
    this.props.loadProjectsInfo(this.props.courseInfo[index].course_id)
  }

  handleChange(e){
    this.setState({curCourseId: e.target.value})
    this.props.loadProjectsInfo(e.target.value)
  }

  handleClose() {
    this.setState({anchorEl:null})
  }

  render(){
    const { classes, courseInfo } = this.props;
    return (
      <div >
        <Select
          value={this.state.curCourseId}
          onChange={this.handleChange}
          displayEmpty
          name="age"
        >
          {courseInfo.map((option, index) => (
            <MenuItem
              key={index}
              value={option.course_id}
            >
              {option.course_code+" "+option.section}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }
}

export default withStyles(styles)(CourseList);
