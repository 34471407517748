/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import {  Router, Switch, Route, BrowserRouter } from 'react-router-dom';

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
import routes from "routes.js";
import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import logo from "assets/img/tk.png"
import endpoint from '../variables/endPoint.js'
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { CookiesProvider } from 'react-cookie';
import {cookies} from '../variables/cookies.js'





class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    //const { cookies } = props;
    var user = ""
    var project = ""
    if(this.props.location.state == undefined){
      user = cookies.get('userInfo')
      project = {
        "couse_id": null,
        "group_name": null,
        "members": [],
        "peer_eva": null,
        "presen_date": "0000000000",
        "project_description": null,
        "project_name": null,
        "ratingFinished": null,
        "section": null
      }
    }
    else{
      user = this.props.location.state.userInfo
      project = this.props.location.state.myProjectInfo
    }

    this.state = {
      userInfo: user,
      myProjectInfo: project,
      image: "",
      color: "blue",
      hasImage: true,
      fixedClasses: "dropdown",
      mobileOpen: false,
      name: cookies.get('name') || 'Ben'
    };
    this.loadMyProjectInfo = this.loadMyProjectInfo.bind(this)

    if(this.props.location.state == undefined){
      this.loadMyProjectInfo(this.state.userInfo)
    }
  }

  loadMyProjectInfo = (userInfo) =>{
    fetch( endpoint +"/user/getdashboardinfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        project_group_id: this.state.userInfo.project_group_id,
        user_id: this.state.userInfo.user_id
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
        } else {
            this.setState({
              myProjectInfo: res.data.myProjectInfo
            })
        }
      });
  }

  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/admin/maps";
  }
  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      const ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });

      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }
  render() {
    const switchRoutes = (
      <Switch>
        {routes.map((prop, key) => {
          if (prop.layout === "/user") {
            return (
              <Route
                path={prop.layout + prop.path}
                render={() =>
                  <prop.component
                    myProjectInfo = {this.state.myProjectInfo}
                    userInfo = {this.state.userInfo}
                    history = {this.props.history}
                  />
                }
                key={key}
              />
            );
          }
        })}
      </Switch>
    );

    const { classes, ...rest } = this.props;
    return (
      <CookiesProvider>
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          logoText={this.state.userInfo.course_code}
          logo={logo}
          layout={"/user"}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          {...rest}
        />
        <div className={classes.mainPanel} ref="mainPanel">
          
          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )}
          {this.getRoute() ? <Footer /> : null}
        </div>
      </div>
    </CookiesProvider>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  cookies: instanceOf(Cookies).isRequired
};

export default withCookies(withStyles(dashboardStyle)(Dashboard));
