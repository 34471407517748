import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Warning from "@material-ui/icons/Warning";

// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Danger from "components/Typography/Danger.jsx"
import Success from "components/Typography/Success.jsx"
import Button from "components/CustomButtons/Button.jsx";


import customTabsStyle from "assets/jss/material-dashboard-react/components/customTabsStyle.jsx";

var feedbackStatus = Object.freeze({"isGiven":1, "notGiven":2, "noNeedToGive":3})

class CustomTabs extends React.Component {
  state = {
    value: 0
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  toFbPage = () => {
    this.setState({ value:1 });
  };

  render() {
    const {
      classes,
      headerColor,
      plainTabs,
      tabs,
      title,
      rtlActive
    } = this.props;
    const cardTitle = classNames({
      [classes.cardTitle]: true,
      [classes.cardTitleRTL]: rtlActive
    });

    var feedbackMessage ;
    var editSubMsg = "You have given feedback to this group"
    var editMessage = (this.props.canEdit)?editSubMsg:"The deadline to give/edit feedback has passed"


    if(!this.props.havePresented){
      feedbackMessage = 
              (<CardBody>
                  <Danger>
                    <Warning style={{ verticalAlign:"middle"}}/>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                    The group hasn't presented yet
                   </a>
                  </Danger>    
                </CardBody>)
    }
    else if(this.props.feedbackStatus == feedbackStatus.isGiven){
      feedbackMessage = 
                (<CardBody>
                  {(this.props.canEdit)?<Button color="primary" onClick={this.toFbPage} style={{marginBottom: "10px"}}>Edit Feedback</Button>:""}
                  <Success>
                    <CheckCircle style={{ verticalAlign:"middle"}}/>
                    <a href="#pablo" style={{color: "green"}} onClick={e => e.preventDefault()}>
                      {editMessage}
                    </a>
                  </Success>
                </CardBody>)
    }
    else if(this.props.feedbackStatus == feedbackStatus.notGiven && this.props.canEdit){
      feedbackMessage = (<CardBody><Button color="primary" onClick={this.toFbPage}>Give Feedback</Button></CardBody>)
    }
    else if(this.props.feedbackStatus == feedbackStatus.notGiven && !this.props.canEdit){
      feedbackMessage = (<CardBody>
                  <Danger>
                    <Warning style={{ verticalAlign:"middle"}}/>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                    The deadline to give/edit feedback has passed
                   </a>
                  </Danger>    
                </CardBody>)
    }
    else{
      feedbackMessage = (<CardBody><Success><CheckCircle style={{ verticalAlign:"middle"}}/>
                          <a href="#pablo" style={{color: "green"}} onClick={e => e.preventDefault()}>
                            You don't need to give feedback to this group
                          </a>
                       </Success></CardBody>)
    };

    return (
      <Card plain={plainTabs}>
        <CardHeader color={headerColor} plain={plainTabs}>
          {title !== undefined ? (
            <div className={cardTitle}>{title}</div>
          ) : null}
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.displayNone,
              scrollButtons: classes.displayNone
            }}
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map((prop, key) => {
              var icon = {};
              if (prop.tabIcon) {
                icon = {
                  icon: <prop.tabIcon />
                };
              }
              return (
                <Tab
                  classes={{
                    root: classes.tabRootButton,
                    labelContainer: classes.tabLabelContainer,
                    label: classes.tabLabel,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper
                  }}
                  key={key}
                  label={prop.tabName}
                  {...icon}
                />
              );
            })}
          </Tabs>
        </CardHeader>
        <CardBody>
          {tabs.map((prop, key) => {
            if (key === this.state.value) {
              return <div key={key}>{prop.tabContent}</div>;
            }
            return null;
          })}
        </CardBody>
        <CardBody>
            {(this.state.value == 0)?feedbackMessage:""}
        </CardBody>
      </Card>
    );
  }
}

CustomTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary"
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.func,
      tabContent: PropTypes.node.isRequired
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool
};

export default withStyles(customTabsStyle)(CustomTabs);
