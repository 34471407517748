import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import RatingPicker from "components/RatingPicker.jsx";
import TextField from '@material-ui/core/TextField';
import PeerEvaGrid from "components/PeerEvaGrid.jsx"
import CircularProgress from "components/CircularProgress.jsx"
//api url
import endpoint from 'variables/endPoint.js'

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};


class PeerEvaluation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      allEvaInfo: [0],
      evaInfoIsLoaded: false
    };
    this.loadEvaInfo = this.loadEvaInfo.bind(this)
    if(this.props.myProjectInfo.peer_eva)
      this.loadEvaInfo();
  }

  loadEvaInfo(){
    fetch( endpoint +"/peer/getEva", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user_id: this.props.userInfo.user_id,
        members: JSON.parse(this.props.myProjectInfo.members),
      })
    })
      .then(res => res.json())
      .then(res => {
        //console.log("res: ", res.err);
        if (res.err) {
        //  console.log(res.err);
        } else {
          //projects info is back
      //    console.log("project info is back: ", res.data.projectsInfo)
          this.setState({
            allEvaInfo: res.data.evaInfo,
            evaInfoIsLoaded: true
          })
        }
      });
  }

  render(){
    const { classes } = this.props;
    var user = this.props.userInfo
    var refreshPage = this.loadEvaInfo
    var showEva = this.props.myProjectInfo.peer_eva
    var eva;
    var evaGrids = this.state.allEvaInfo.map( function(eva){
          return <PeerEvaGrid
            evaInfo={eva}
            userInfo={user}
            refreshPage = {refreshPage}
            key = {eva.to_user}
          />
      });
    if(showEva){
      eva = <div>
        {this.state.evaInfoIsLoaded?evaGrids:<CircularProgress/>}
      </div>
    }
    else
      eva = "Peer evaluation is not available right now"

    return (
      <div>
        {eva}
      </div>
    );
  }
}

export default withStyles(styles)(PeerEvaluation);
