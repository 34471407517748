import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {Route, withRouter, Redirect,Link } from 'react-router-dom';
import Dashboard from './Admin.jsx'
import {connect} from 'react-redux'
import Danger from "components/Typography/Danger.jsx";
import endpoint from '../variables/endPoint.js'
import {cookies} from '../variables/cookies.js'



const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      pwdMsg: "",
      isLoading: false
    };
    this.handleLogin = this.handleLogin.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleForget = this.handleForget.bind(this)
  }

  loadDashboardData = (userInfo) =>{
    fetch( endpoint +"/user/getdashboardinfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        project_group_id: userInfo.project_group_id,
        user_id: userInfo.user_id
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
        } else {
            this.props.history.push({
              pathname: "/user/dashboard",
              state: {
                userInfo: userInfo,
                myProjectInfo: res.data.myProjectInfo
              }
            });
        }
      });
  }

  loadAdminData(userInfo){
    this.props.history.push({
      pathname: "/admin/dashboard",
      state: {
        userInfo: userInfo,
      }
    });
  }


  handleLogin(e){
    e.preventDefault();
    this.setState({
      isLoading: true
    })
    if (this.state.username.length === 0 || this.state.password.length === 0) {
      this.setState({
        pwdMsg:"username/password cannot be empty!",
        isLoading: false
      })
      return;
    }
    fetch( endpoint +"/user/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username: this.state.username,
        password: this.state.password
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
          this.setState({
            pwdMsg:"username or password incorrect",
            isLoading: false
          })
        } else {
          //success login
          if(this.state.username == "koh@ust.hk"){
            console.log("load admin data", res.data.userInfo)
            this.loadAdminData(res.data.userInfo)
          }
          else{
            if(res.data.userInfo.project_group_id == -1){
                this.setState({
                  pwdMsg:"Your account is invalid. Please contact course instructor.",
                  isLoading: false
                })
            }
            else
              this.loadDashboardData(res.data.userInfo);
          }
        }
      });
  }

  handleInputChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    });
  }

  handleForget(e){
    this.props.history.push({
      pathname: "/forget",
    });
  }


  render(){
    const { classes} = this.props;
    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Course Console
          </Typography>
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="username">Your HKUST Email Address</InputLabel>
              <Input id="username" name="username" onChange={this.handleInputChange} autoFocus />
            </FormControl>
            <div style={{'fontSize': '12px'}}>e.g. username@connect.ust.hk</div>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input name="password" type="password" id="password" onChange={this.handleInputChange}/>
            </FormControl>
            <Button className={classes.button} onClick={this.handleForget}>Forget password?</Button>
            <Danger>{this.state.pwdMsg}</Danger>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.handleLogin}
             disabled={this.state.isLoading}
            >
              Sign in
            </Button>
          </form>
        </Paper>
        {(this.state.isLogin)?this.handleLogin():""}
      </main>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

const LoginPage = withRouter(withStyles(styles)(Login))
export default LoginPage
