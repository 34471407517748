import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import KeyIcon from '@material-ui/icons/VpnKey';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {Route, withRouter, Redirect } from 'react-router-dom';
import Dashboard from './Admin.jsx'
import Danger from "components/Typography/Danger.jsx";
import Success from "components/Typography/Success.jsx";
import CircularProgress from "components/CircularProgress.jsx"
import endpoint from '../variables/endPoint.js'
import {connect} from 'react-redux'

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  row:{
    display:'flex',
    flexDirection:'row'
  },
  code:{
    width: '50%'
  },
  getCode:{
    height: '40px',
    width: '170px',
    marginLeft: '10px',
    marginTop: theme.spacing.unit * 3
  }
});

class Forget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      code: "",
      success: false,
      time:30,
      btnIsDisabled: false,
      codeMsg: "",
      dangerTypo:true,
      isConfirming: false,
      countDown:false
    };
    this.intervalHandle = "";
    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
  }


  handleConfirm(e){

    if(!this.validateEmail(this.state.username)){
      this.setState({
        codeMsg: "Your email is invalid",
        dangerTypo: true
      })
      return;
    }
    
    this.setState({
      isConfirming:true
    })

    fetch( endpoint +"/user/forgetpassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        to: this.state.username,
        username: this.state.username
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
          console.log("error: ", res.err)
          this.setState({
            codeMsg: "No account asscoiated with this email",
            isConfirming: false,
            dangerTypo: true
          })
        }
        else {
          console.log("success")
          this.setState({
            isConfirming: false,
            success: true
          })
        }
      });
  }

  handleInputChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    });
  }

  startCountDown() {
    this.intervalHandle = setInterval(this.tick, 1000);
  }


  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleLogin(e){
    this.props.history.push({
      pathname: "/",
    });
  }


  render(){
    const { classes} = this.props;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <KeyIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forget password
          </Typography>
          {(!this.state.success)?(
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="username">Input your ITSC email address</InputLabel>
              <Input id="username" name="username" onChange={this.handleInputChange} autoFocus />
            </FormControl>
            {(this.state.dangerTypo)?
            <Danger className={classes.cardTitle}>{this.state.codeMsg}</Danger>:
              <Success className={classes.cardTitle}>{this.state.codeMsg}</Success>}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.handleConfirm}
              disabled={this.state.isConfirming}
            >
              {(this.state.isConfirming)?"Loading...":"Confirm"}
            </Button>
          </form>):(<div>The new password has been sent to your ITSC email. Please check your spam folder if you do not receive the email in 1 minute.
          <Button className={classes.button} onClick={this.handleLogin}>Login</Button>
          </div>)}
        </Paper>
      </main>
    );
  }
}

Forget.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Forget);
