import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Tasks from "components/Tasks/Tasks.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Danger from "components/Typography/Danger.jsx";
import Info from "components/Typography/Info.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "components/CustomButtons/Button.jsx";
import RatingPicker from "components/RatingPicker.jsx";
import Success from "components/Typography/Success.jsx"
import StarRatings from 'react-star-ratings';
import endpoint from '../variables/endPoint.js'
import { bugs, website, server } from "variables/general.jsx";
import { SnackbarProvider, withSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from './CircularProgress.jsx'
import Divider from '@material-ui/core/Divider';

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";


class FeedbackGrid extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      rating: 0,
      isSubmitting: false
    };
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleRateChange = this.handleRateChange.bind(this)
  }

  handleSubmit(rating){
    this.setState({
      isSubmitting: true
    })
    fetch( endpoint +"/rating/uploadrating", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        from_user: this.props.userInfo.user_id,
        from_project_group: this.props.userInfo.project_group_id,
        to_user: this.props.feedbackInfo.from_user,
        course_id: this.props.userInfo.course_id,
        section: this.props.userInfo.section,
        rating: rating
      })
    })
      .then(res => res.json())
      .then(res => {
        this.setState({
          isSubmitting: false
        })
        if (res.err) {
          this.props.enqueueSnackbar('Submission failed', { variant: 'warning' });
        } else {
        this.props.enqueueSnackbar('Your rating is submitted!', { variant: 'success' });
         this.props.refreshPage(0);
        }
      });
  }

  handleRateChange(rating){
    this.setState({
      rating: rating
    })
    this.handleSubmit(rating)
  }

  render() {
    const { classes } = this.props;
    var textStyle = {
      marginRight: '20px',
    };


    return (
      <GridContainer>
        <Dialog
          open={this.state.isSubmitting}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Loading..."}</DialogTitle>
          <DialogContent>
            <CircularProgress/>
          </DialogContent>
        </Dialog>
        <GridItem xs={24} sm={24} md={10}>
          <Card>
            <CardHeader color="warning" style={{height: 0.5}}>
              </CardHeader>           
            <CardBody>
              <h8>
                {this.props.feedbackInfo.feedback_content}
              </h8>
            </CardBody>
            <div style={{display: 'inline-flex'}}>
            <CardFooter>
                <div style={{marginRight:"20px", color:"grey", fontSize:"smaller"}}>Your Rating</div>
                {(this.props.isUnrated)?
                <StarRatings
                 starDimension="20px"
                 rating={this.state.rating}
                 starRatedColor="red"
                 changeRating={this.handleRateChange}
                 numberOfStars={5}
                 name='rating'
               /> : <div>
                       <StarRatings
                        starDimension="15px"
                        rating={this.props.feedbackInfo.rating}
                        starRatedColor="red"
                        numberOfStars={5}
                        name='rating'
                      />
                    </div>}
            </CardFooter>
            {(!this.props.isUnrated)?
              <CardFooter>
                  <h8 style={{marginRight:"20px", color:"grey", fontSize:"smaller"}}>Group Average Rating</h8>
                  <StarRatings
                   starDimension="15px"
                   rating={Math.round(this.props.feedbackInfo.avgRating)}
                   starRatedColor="red"
                   numberOfStars={5}
                   name='rating'
                 />
             </CardFooter>:""}
           </div>
          </Card>
        </GridItem>
      </GridContainer>
          );
  }
}


FeedbackGrid.propTypes = {
  classes: PropTypes.object.isRequired
};
const MyApp = withSnackbar(FeedbackGrid);


export default withStyles(dashboardStyle)(MyApp);
