import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";


import CheckCircle from "@material-ui/icons/CheckCircle";
import Success from "components/Typography/Success.jsx"

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Tasks from "components/Tasks/Tasks.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Danger from "components/Typography/Danger.jsx";
import Info from "components/Typography/Info.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "components/CustomButtons/Button.jsx";
import RatingPicker from "components/RatingPicker.jsx";
import TextField from '@material-ui/core/TextField';
import { bugs, website, server } from "variables/general.jsx";
import endpoint from '../variables/endPoint.js'
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";


class PeerEvaGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      projectMemberNames: [],
      evaData: {
        "num_question": 3,
        "questions":[{"type": "rating","value":"1"},
          {"type": "rating","value": "1"},
          {"type": "comments","value": ""}]
      }
    };
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleAnswerChange = this.handleAnswerChange.bind(this)
    this.handleCommentsChange = this.handleCommentsChange.bind(this)
  }



  handleAnswerChange(indexOfQuestion, answer){
    let temp = this.state.evaData
    temp.questions[indexOfQuestion].value = answer;
    this.setState({
      evaData:temp
    })
  //  this.evaData.questions[indexOfQuestion].value = answer;
  }

  handleCommentsChange(e){
    let temp = this.state.evaData
    temp.questions[2].value = e.target.value;
    this.setState({
      evaData:temp
    })
  }

  handleSubmit(){
    fetch( endpoint +"/peer/uploadeva", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userInfo: this.props.userInfo,
        evaInfo: {
          to_user: this.props.evaInfo.user_id,
          evaContent: JSON.stringify(this.state.evaData)
        }
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
          alert("Submission failed")
        } else {
          alert("Your evaluation is submitted")
         this.props.refreshPage();
        }
      });
  }

  render() {
    const { classes } = this.props;

  //  const projectInfo = this.props.projectInfo

    const evaContent = "";
    if(this.props.evaInfo.eva_content != undefined)
      evaContent = JSON.parse(this.props.evaInfo.eva_content)
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>{this.props.evaInfo.name}</h4>
            </CardHeader>
            <CardBody>
      {(this.props.evaInfo.eva_content==null)?(
        <GridContainer>
          <GridItem>
           <RatingPicker question="How committed is your groupmate?" indexOfQuestion={0} handlePicking={this.handleAnswerChange}/>
         </GridItem>
         <GridItem xs={12} sm={12}>
           <RatingPicker question="How much does he contribute to the work?" indexOfQuestion={1} handlePicking={this.handleAnswerChange}/>
         </GridItem>
         <GridItem xs={12} sm={12}>
           <TextField
             id="outlined-textarea"
             label="General comments on your groupmate"
             placeholder="Placeholder"
             multiline
             className={classes.textField}
             margin="normal"
             variant="outlined"
             fullWidth
             onChange={this.handleCommentsChange}
           />
         </GridItem>
       </GridContainer>):(
       <GridItem xs={12} sm={12} md={12}>
           <CardBody>
             <h4 className={classes.cardCategory}>How committed is your groupmate?</h4>
             <p className={classes.description}>
               {evaContent.questions[0].value}
             </p>
             <h4 className={classes.cardCategory}>How much does he contribute to the work?</h4>
             <p className={classes.description}>
               {evaContent.questions[1].value}
             </p>
             <h4 className={classes.cardCategory}>General comments on your groupmate</h4>
             <p className={classes.description}>
               {evaContent.questions[2].value}
             </p>
           </CardBody>
       </GridItem>)}
           </CardBody>
           <CardFooter>
             {(this.props.evaInfo.eva_content==null)?
             <Button color="primary" onClick={this.handleSubmit}>Submit Evaluation</Button>
             :<Success><CheckCircle/>You have already given evaluation to your groupmate</Success>}
           </CardFooter>
         </Card>
       </GridItem>
      </GridContainer>

    );
  }
}

PeerEvaGrid.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(PeerEvaGrid);
