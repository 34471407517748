var beautifyDate = function(date){
	var month = date.split("-")[0]
    if(month.length == 1)
      month = "0"+month
    var wordMonth = ""
    console.log("beautifyDate: ", date, month)
    switch(month){
      case "01":
        wordMonth = "Jan"
        break;
      case "02":
        wordMonth = "Feb"
        break;
      case "03":
        wordMonth = "Mar"
        break;
      case "04":
        wordMonth = "Apr"
        break;
      case "05":
        wordMonth = "May"
        break;
      case "06":
        wordMonth = "Jun"
        break;
      case "07":
        wordMonth = "Jul"
        break;
      case "08":
        wordMonth = "Aug"
        break;
      case "09":
        wordMonth = "Sep"
        break;
      case "10":
        wordMonth = "Oct"
        break;
      case "11":
        wordMonth = "Nov"
        break;
      case "12":
        wordMonth = "Dec"
        break;
    }
    return date.split("-")[1]+"-"+wordMonth+"-"+date.split("-")[2]
}

module.exports = beautifyDate;