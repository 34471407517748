import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import Table from "components/Table/Table.jsx";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Danger from "components/Typography/Danger.jsx";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddIcon from '@material-ui/icons/Add';
import { SnackbarProvider, withSnackbar } from 'notistack';


//api url
import endpoint from 'variables/endPoint.js'



const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  section1: {
    display: "flex",
    flexDirection: "row"
  },

  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  semBtns:{
    display: "flex",
    flexDirection: "row"
      }
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curSemester: this.props.curSemester,
      iHaveRatedAll: -1,
      courseInfo:[],
      openSem: false,
      openCourse: false,
      formMsg: "",
      formQuestions:[],
      newSemName: "",
      allSems: this.props.allSems,
      isLoading: false,
      newCourseInfo:{},
      createCourseMsg: ""
    };
    this.handleSemChange = this.handleSemChange.bind(this)
    this.handleSemInputChange = this.handleSemInputChange.bind(this)
    this.handleCourseInputChange = this.handleCourseInputChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    console.log("child component did update", prevProps.curSemester,"few", this.props.curSemester)
    if(prevProps.curSemester != this.props.curSemester){
      this.setState({
        curSemester: this.props.curSemester,
        allSems: this.props.allSems
      })
        console.log("child inside function")
       this.updateCourseInfo(this.props.curSemester)
    }

  }

  componentDidMount(){
    this.loadCourseInfo();
  }

  handleSemChange = (event) => {
    console.log("new sem", event.target.value)
    this.setState({ curSemester: event.target.value });
    this.props.updateGlobalCurSem(event.target.value)
    this.updateCourseInfo(event.target.value)
  };

  handleSemCreateOpen = (event) => {
    this.setState({ openSem: true });
  };

  handleCourseCreateOpen = (event) => {
    this.setState({ openCourse: true });
  };



  updateCourseInfo = (sem) =>{
    console.log("child update courseInfo: ", sem)
    fetch( endpoint +"/admin/getCourseInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        semester: sem
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
        } else {
            this.setState({
              courseInfo: res.data
            })
            console.log("updateCourseInfo: ", res.data)
        }
      });
  }



  loadCourseInfo = (userInfo) =>{
    console.log("childdd loadCourseInfo: ", this.state.curSemester)
    // var semesters = this.props.userInfo.screen_name.split(";")
    // var curSemester = semesters[semesters.length-1];//for admin account, cur sem is stored in screen_name
    // this.setState({
    //   allSems: semesters
    // })

    fetch( endpoint +"/admin/getCourseInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        semester: this.state.curSemester
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
        } else {
            this.setState({
              courseInfo: res.data
            })
        }
      });
  }

  handleClose(){

  }

  handleCreateSem(state){
    console.log("create sem: ",this.state.newSemName)

    this.setState({
      isLoading: true
    })

    if(state == 0)
      this.setState({openSem: false, newSemName:""})
    else if(state == 1){
      fetch( endpoint +"/admin/createSem", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        semester: this.state.newSemName
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
        } else {
          this.props.enqueueSnackbar('New semester is created!', { variant: 'success' });

          let temp = this.state.allSems
          temp.push(this.state.newSemName)

            this.setState({
              curSemester: this.state.newSemName,
              allSems: temp
            })
        }
        this.setState({
          isLoading: false,
          openSem: false,
          courseInfo: []
        })
      });
    }
  }

  handleCreateCourse(state){
    console.log(state)
    if(state == 0)
      this.setState({openCourse: false, newCourseInfo:{}})
    else if(state == 1){
      if(this.checkNewCourseInfoValidity()){

        var courseInfo = this.state.newCourseInfo
        courseInfo["semester"] = this.state.curSemester
        //fetch
        fetch( endpoint +"/admin/createCourse", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            course_info: courseInfo
          })
        })
          .then(res => res.json())
          .then(res => {
            if (res.err) {
              this.props.enqueueSnackbar('Fail to create course', { variant: 'warning' });
            } else {
              this.props.enqueueSnackbar('New course is created!', { variant: 'success' });
              this.updateCourseInfo(this.state.curSemester)
              this.props.updateGlobalCurSem(this.state.curSemester)
              this.setState({openCourse: false, newCourseInfo:{}})
            }
          });
      }
     // this.setState({openCourse: false})
    }
  }

  handleSemInputChange(e){
    console.log(e.target.value)
    this.setState({
      newSemName: e.target.value
    })
  }

  checkFormValidity(form){
     try {
        var form = JSON.parse(form)
        console.log(form)
        this.setState({
          formMsg: "",
          formQuestions: form["questions"]
        })
        } catch (err) {
        this.setState({
          formMsg: "invalid input form"
        })
      }
  }

  checkNewCourseInfoValidity(){
    var courseMsg = ""
    var newCourseInfo = this.state.newCourseInfo

    if(newCourseInfo["course_code"] == undefined || newCourseInfo["course_code"] == "")
      courseMsg = "Course code cannot be empty"
    else if(newCourseInfo["section"] == undefined || newCourseInfo["section"] == "")
      courseMsg = "Section cannot be empty"
    else if(newCourseInfo["course_name"] == undefined || newCourseInfo["course_name"] == "")
      courseMsg = "Course name cannot be empty"
    else if(newCourseInfo["fb_form"] == undefined || newCourseInfo["fb_form"] == "")
      courseMsg = "Course form cannot be empty"
    else if(newCourseInfo["ins_fb_mode"] == undefined)
      courseMsg = "Instructor feedback mode cannot be empty"
    else if(newCourseInfo["rate_fb_qindex"] == undefined){
      courseMsg = "Question for peer evaluation cannot be empty"
    }
    else if(newCourseInfo["cal_gp_avg_qindex"] == undefined)
      courseMsg = "Question for group average score calculation cannot be empty"
    else if(newCourseInfo["fb_ddl"] == undefined)
      courseMsg = "Deadline to submit presentation evaluation cannot be empty"
     
    this.setState({
      createCourseMsg: courseMsg
    })

    if(courseMsg != "")
      return false
    return true
  }


  handleCourseInputChange(e){
    var field = e.target.name
    console.log(field, e.target.value)
    var prevCourseInfo = this.state.newCourseInfo

    switch(field){
      case "courseName":
        prevCourseInfo["course_name"] = e.target.value
        break;
      case "courseCode":
        prevCourseInfo["course_code"] = e.target.value
        break;
      case "section":
        prevCourseInfo["section"] = e.target.value
        break;
      case "courseForm":
        prevCourseInfo["fb_form"] = e.target.value
        this.checkFormValidity(e.target.value)
        break;
      case "rateFBIndex":
        prevCourseInfo["rate_fb_qindex"] = e.target.value
        break;
      case "groupAvgIndex":
        prevCourseInfo["cal_gp_avg_qindex"] = e.target.value
        break;
      case "insFBMode":
        prevCourseInfo["ins_fb_mode"] = e.target.value
        break;
      case "fbDDL":
        prevCourseInfo["fb_ddl"] = e.target.value
        break
      case "showPresenDate":
        prevCourseInfo["show_presen_date"] = e.target.value
    }
 
    this.setState({
      newCourseInfo: prevCourseInfo
    })

  }

  render() {
    const { classes} = this.props;
    console.log("render: ", this.state.courseInfo)

    const bodySem = (
        <div style={{backgroundColor:"white"}}>
          <div className={classes.paper}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="username">Semester Name</InputLabel>
                <Input id="username" name="username" onChange={this.handleSemInputChange} autoFocus />
              </FormControl>
              <Danger>{this.state.pwdMsg}</Danger>
              <div className={classes.semBtns}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submit}
                  onClick={() => this.handleCreateSem(0)}
                 disabled={this.state.isLoading}
                 style={{marginRight:"10%"}}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => this.handleCreateSem(1)}
                 disabled={this.state.isLoading}
                >
                  Create
                </Button>
              </div>
          </div>
        </div>
      );

     const bodyCourse = (
        <div style={{backgroundColor:"white"}}>
          <div className={classes.paper}>
              <div className={classes.section1}>
                <FormControl margin="normal" required fullWidth style={{marginRight:"10%"}}>
                  <InputLabel htmlFor="username">Course Code</InputLabel>
                  <Input id="courseCode" name="courseCode" onChange={this.handleCourseInputChange} autoFocus />
                </FormControl>
                <FormControl margin="normal" required fullWidth style={{marginRight:"10%"}}>
                  <InputLabel htmlFor="username">Section</InputLabel>
                  <Input id="section" name="section" onChange={this.handleCourseInputChange}  />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="username">Course Name</InputLabel>
                  <Input id="courseName" name="courseName" onChange={this.handleCourseInputChange}  />
                </FormControl>
              </div>
              <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="username">Presentation Evaluation Form</InputLabel>
                  <Input id="courseForm" name="courseForm" onChange={this.handleCourseInputChange}  />
              </FormControl>
              <Danger>{this.state.formMsg}</Danger>
              <FormControl margin="normal" required fullWidth>
                Question for Presentation Evaluation
                <Select
                  value={this.state.newCourseInfo["rate_fb_qindex"]}
                  onChange={this.handleCourseInputChange}
                  displayEmpty
                  name="rateFBIndex"
                  style={{marginBottom: "20px"}}
                  id="rateFBIndex"

                >
                  {this.state.formQuestions.map((value, index) => {
                    return <MenuItem value={index}>{value["description"]}</MenuItem>
                  })}
                </Select>
                Question for Presentation Scoring
                <Select
                  id="groupAvgIndex"
                  value={this.state.newCourseInfo["cal_gp_avg_qindex"]}
                  onChange={this.handleCourseInputChange}
                  displayEmpty
                  name="groupAvgIndex"
                  style={{marginBottom: "20px"}}
                  
                >
                  {this.state.formQuestions.map((value, index) => {
                    return <MenuItem value={index}>{value["description"]}</MenuItem>
                  })}
                </Select>
                 Show Instructor's Feedback when
                <Select
                  value={this.state.newCourseInfo["ins_fb_mode"]}
                  onChange={this.handleCourseInputChange}
                  displayEmpty
                  name="insFBMode"
                  style={{marginBottom: "20px"}}
                >
                  <MenuItem value="me">The student has given all feedbacks</MenuItem>
                  <MenuItem value="group">The student and all group members have rated all feedbacks</MenuItem>
                </Select>
                Deadline to Submit Presentation Evaluation
                <Select
                  value={this.state.newCourseInfo["fb_ddl"]}
                  onChange={this.handleCourseInputChange}
                  displayEmpty
                  name="fbDDL"
                  style={{marginBottom: "20px"}}
                >
                  <MenuItem value="0">+0 day (same day of the group's presentation)</MenuItem>
                  <MenuItem value="1">+1 day</MenuItem>
                  <MenuItem value="2">+2 days</MenuItem>
                  <MenuItem value="3">+3 days</MenuItem>
                  <MenuItem value="4">+4 days</MenuItem>
                </Select>
                Show Presentation Date in Student Console
                <Select
                  value={this.state.newCourseInfo["show_presen_date"]}
                  onChange={this.handleCourseInputChange}
                  displayEmpty
                  name="showPresenDate"
                  style={{marginBottom: "20px"}}
                >
                  <MenuItem value="1">Yes</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </Select>
              </FormControl>
              <Danger>{this.state.createCourseMsg}</Danger>
              <div className={classes.semBtns}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submit}
                  onClick={() => this.handleCreateCourse(0)}
                 disabled={this.state.isLoading}
                 style={{marginRight:"10%"}}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => this.handleCreateCourse(1)}
                 disabled={this.state.isLoading}
                >
                  Create
                </Button>
              </div>
          </div>
        </div>
      );

    return (
      <div>
          <Select
            value={this.state.curSemester}
            onChange={this.handleSemChange}
            displayEmpty
            name="age"
          >
            {this.state.allSems.map((value, index) => {
              return <MenuItem value={value}>{value}</MenuItem>
            })}
          </Select>
          <Button onClick={this.handleSemCreateOpen} style={{ marginLeft: '2%' }}>Create Semester</Button>
          <Modal
            open={this.state.openSem}
            onClose={this.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{top:"20%", left:"50%", width:"300px"}} 
          >
          {bodySem}
          </Modal>
        <SnackbarProvider maxSnack={3}>
          <Table courseInfo={this.state.courseInfo} updateCourseInfo={this.loadCourseInfo}></Table>
        </SnackbarProvider>
          {(this.state.courseInfo.length == 0)? <div>No course in this semester</div>:""}
          <Button
            variant="contained"
            onClick={this.handleCourseCreateOpen}
            color="default"
            style={{marginLeft: "85%", marginTop: "20px"}}
            startIcon={<AddIcon />}
          >
            Add Course
          </Button>
          <Modal
            open={this.state.openCourse}
            onClose={this.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{top:"5%", left:"30%", width:"600px"}} 
          >
            {bodyCourse}
          </Modal>

      </div>
    );
  }
}



Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

const ins = withSnackbar(Dashboard);
export default withStyles(styles)(ins);
