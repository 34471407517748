import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import OthersProjectGrid from "components/OthersProjectGrid.jsx"
import PropTypes from "prop-types";
import CircularProgress from "components/CircularProgress.jsx"
import { SnackbarProvider, withSnackbar } from 'notistack';
//api url
import endpoint from 'variables/endPoint.js'


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

var feedbackStatus = Object.freeze({"isGiven":1, "notGiven":2, "noNeedToGive":3})

class GiveFeedback extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      allProjectsInfo: [],
      projectsInfoIsLoaded: false
    };
    this.loadProjectsInfo = this.loadProjectsInfo.bind(this)
    this.sortProjectsByDate = this.sortProjectsByDate.bind(this)
    this.loadProjectsInfo()
  }

  processProjectInfo(projects){
    let myProjectGroupInfo = this.props.myProjectInfo
    let projectsInfo = projects
    //1. exclude my project group info
    //2. if given feedback, set feedback status = 1
    //  if same presendate, set feedback status = 0
    //  if different presen date, set status = 3

    for(let i=0; i<projectsInfo.length; i++){
        if(projectsInfo[i].project_group_id == myProjectGroupInfo.project_group_id){
          projectsInfo[i].feedbackStatus = feedbackStatus.isMyProject
        }
        else if(projectsInfo[i].myFeedback)
          projectsInfo[i].feedbackStatus = feedbackStatus.isGiven
        else if(projectsInfo[i].presen_date == myProjectGroupInfo.presen_date)
          projectsInfo[i].feedbackStatus = feedbackStatus.noNeedToGive
        else
          projectsInfo[i].feedbackStatus = feedbackStatus.notGiven;
    }
    this.setState({
      allProjectsInfo: projectsInfo,
      projectsInfoIsLoaded: true
    })
  }

  loadProjectsInfo(){
    fetch( endpoint +"/project/getAllProjects", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        course_id: this.props.userInfo.course_id,
        section: this.props.userInfo.section,
        user_id: this.props.userInfo.user_id
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
        } else {
          //projects info is back
          this.processProjectInfo(res.data.projectsInfo)
        }
      });
  }

  sortProjectsByDate(allProjects){
    let flag = true
    console.log("start sorting", allProjectsInfo)
    let allProjectsInfo = allProjects

    while(flag){
      flag = false
      for(let i=0; i<allProjectsInfo.length-1; i++){
        let curPJDate = new Date(allProjectsInfo[i].presen_date.replace(/-/g, "/"))
      let nextPJDate = new Date(allProjectsInfo[i+1].presen_date.replace(/-/g, "/"))
        if(curPJDate > nextPJDate){
          let temp = allProjectsInfo[i]
          allProjectsInfo[i] = allProjectsInfo[i+1]
          allProjectsInfo[i+1] = temp
          flag = true
          console.log("swapping")
        }
      }
    }
    return allProjectsInfo
}

  render(){
    const { classes } = this.props;
    var myPGId = this.props.myProjectInfo.project_group_id
    var user = this.props.userInfo
    var refreshPage = this.loadProjectsInfo
    var fbDataTemplate = JSON.parse(JSON.stringify(user.fb_form))
    var courseInfo = this.props.myProjectInfo.courseInfo

    for(let i=0; i<fbDataTemplate.num_question; i++){
      delete fbDataTemplate.questions[i]['description']
      fbDataTemplate.questions[i].value = ""
      if(fbDataTemplate.questions[i].type == "rating"){
        delete fbDataTemplate.questions[i]['values']
        fbDataTemplate.questions[i].value = -1
      }
    }
    var projects = this.sortProjectsByDate(this.state.allProjectsInfo).map( function(project){
        if(project.project_group_id != myPGId)
          return <OthersProjectGrid
            projectInfo={project}
            fbDataTemplate={fbDataTemplate}
            userInfo={user}
            refreshPage={refreshPage}
            key = {project.project_group_id}
            courseInfo = {courseInfo}
          />
      });

    return (
      <GridContainer>
        <SnackbarProvider maxSnack={3}>
           {this.state.projectsInfoIsLoaded?projects:<div style={{marginTop: "20%", marginLeft: "40%"}}>
              <CircularProgress/>
            </div>}          
          </SnackbarProvider>
      </GridContainer>
    );
  }
}
//        {this.state.projectsInfoIsLoaded?projects:<CircularProgress style={{marginTop: 100px}}/>}

GiveFeedback.propTypes = {
  classes: PropTypes.object.isRequired,
  projects: PropTypes.arrayOf(),
};

export default withStyles(styles)(GiveFeedback);
