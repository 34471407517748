import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Success from "components/Typography/Success.jsx"
import People from "@material-ui/icons/Person";
import Star from "@material-ui/icons/Star";
import Feedback from "@material-ui/icons/Feedback";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Tasks from "components/Tasks/Tasks.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Danger from "components/Typography/Danger.jsx";
import Info from "components/Typography/Info.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import MyProjectGrid from "components/MyProjectGrid.jsx";
import { bugs, website, server } from "variables/general.jsx";
import CircularProgress from "components/CircularProgress.jsx"
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
//api url
import endpoint from 'variables/endPoint.js'

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    var length = 0;
    if(props.myProjectInfo.members == undefined)
      length = 0
    else length = props.myProjectInfo.members.length

    this.state = {
      value: 0,
      numData: {
        "fb_to_give": 0,
        "fb_given": 0,
        "fb_received": 0,
        "fb_rated": 0,
        "peer_to_give": length,
        "perr_given": 0
      },
      numIsLoaded: false,
      refreshPage: true,
      iHaveRatedAll: -1
    };
    this.loadNumbers = this.loadNumbers.bind(this)
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  componentDidUpdate(prevProps) {
      if(prevProps.myProjectInfo.course_id == null && this.props.myProjectInfo.course_id != null){
        this.loadNumbers()
      }
  }

  loadNumbers(){
    fetch( endpoint +"/user/getdashboardnum", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user_id: this.props.userInfo.user_id,
        projectInfo: this.props.myProjectInfo,
      })
    })
      .then((res) => {if (res.ok) {
            return res.json();
          } else {
            throw new Error('Something went wrong');
          }})
      .then(res => {
        if (res.err) {
        } else {
          //projects info is back
          var length = 0;
          if(this.props.myProjectInfo.members.length == 0)
            length = 0
          else length = JSON.parse(this.props.myProjectInfo.members).length-1

          let data = {
            "fb_to_give": res.data[0]['count'],
            "fb_given": res.data[1]['count'],
            "fb_received": res.data[2]['count'],
            "fb_rated": res.data[3]['count'],
            //"peer_to_give": length,
            //"peer_given": res.data[4]['count']
          }
          let showInsFeedback = false;
          if((data.fb_received - data.fb_rated) == 0 && (data.fb_to_give-data.fb_given)==0)
            showInsFeedback =true
          else
            showInsFeedback = false
          this.setState({
            numData: data,
            numIsLoaded:true,
            iHaveRatedAll: showInsFeedback
          })
        }
      }).catch(console.error);
  }

  handleRedirection(target){
    console.log(target)
    if(target == "0")
      this.props.history.push({
        pathname: "/user/givefeedback"
      });
    else if(target == "1")
      this.props.history.push({
        pathname: "/user/ratefeedback"
      });
  }

  render() {
    const myProjectInfo = this.props.myProjectInfo
    const { classes } = this.props;
    const evaData =this.state.numData;

    if(!this.state.numIsLoaded)
      this.loadNumbers()
    var num_notgiven_fb = evaData.fb_to_give-evaData.fb_given
    var num_notrated_fb = evaData.fb_received-evaData.fb_rated
    var num_notgiven_peer = evaData.peer_to_give-evaData.peer_given

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Feedback/>
                </CardIcon>
                <p className={classes.cardCategory}>Feedback Status</p>
                <h3 className={classes.cardTitle}>
                  {(this.state.numIsLoaded)?(<div>{evaData.fb_given}/{evaData.fb_to_give}</div>):<CircularProgress/>}
                  <small></small>
              </h3>
              </CardHeader>
              <CardFooter stats onClick={() => this.handleRedirection(0)}>
                {(num_notgiven_fb == 0)?
                  (
                <div className={classes.stats}>
                  <Success>
                    <CheckCircle />
                  </Success>
                  <a href="#pablo" style={{ color: 'green' }}>
                    You have given all feedback(s)
                  </a>
                </div>):(
                <div className={classes.stats}>
                  <Danger>
                    <Warning />
                  </Danger>
                  <a href="#pablo">
                    You have {evaData.fb_to_give-evaData.fb_given} incomplete feedback(s)
                  </a>
                </div>)}
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Star />
                </CardIcon>
                <p className={classes.cardCategory}>Rating Status</p>
                <h3 className={classes.cardTitle}>
                  {(this.state.numIsLoaded)?(<div>{evaData.fb_rated}/{evaData.fb_received}</div>):<CircularProgress/>}
                </h3>
              </CardHeader>
              <CardFooter stats onClick={() => this.handleRedirection(1)} >
                {(num_notrated_fb == 0)?(
                <div className={classes.stats}>
                  <Success>
                    <CheckCircle />
                  </Success>
                  <a href="#pablo" style={{ color: 'green' }}>
                    No feedback to be rated
                  </a>
                </div>):(
                <div className={classes.stats}>
                  <Danger>
                    <Warning />
                  </Danger>
                  <a href="#pablo">
                    You have {evaData.fb_received-evaData.fb_rated} unrated feedback(s)
                  </a>
                </div>)}
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <MyProjectGrid projectInfo = {myProjectInfo} iHaveRatedAll={this.state.iHaveRatedAll}/>
      </div>
    );
  }
}

/*
<GridItem xs={12} sm={6} md={3}>
  <Card>
    <CardHeader color="success" stats icon>
      <CardIcon color="success">
        <People />
      </CardIcon>
      <p className={classes.cardCategory}>Peer Evaluation</p>
      {(myProjectInfo.peer_eva)?<h3 className={classes.cardTitle}>{evaData.peer_given}/{evaData.peer_to_give}</h3>:""}
    </CardHeader>
    <CardFooter stats>
      {peer_eva_info}
    </CardFooter>
  </Card>
</GridItem>*/


Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
