// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import People from "@material-ui/icons/Person";
import School from "@material-ui/icons/School";
import Star from "@material-ui/icons/Star";
import Feedback from "@material-ui/icons/Feedback";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Key from "@material-ui/icons/VpnKey";
import FaceIcon from '@material-ui/icons/Face';
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
import Exit from "@material-ui/icons/ExitToApp";
// core components/views for user layout
import UserDashboard from "views/User/Dashboard/Dashboard.jsx";
import GiveFeedback from "views/User/GiveFeedback/GiveFeedback.jsx"
import RateFeedback from "views/User/RateFeedback/RateFeedback.jsx"
import PeerEvaluation from "views/User/PeerEvaluation/PeerEvaluation.jsx"
import Logout from "views/User/Logout/Logout.jsx"
import ChangePassowrd from "views/User/ChangePassword/ChangePassword.jsx"
// core components/views for admin layout
import AdminDashboard from "views/Admin/Dashboard/Dashboard.jsx";
import AdminGiveFB from "views/Admin/GiveFeedback/GiveFeedback.jsx";
import AdminForm from "views/Admin/Form/Form.jsx";
import AdminStudentInfo from "views/Admin/StudentInfo/StudentInfo.jsx";



const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: UserDashboard,
    layout: "/user"
  },
  {
    path: "/givefeedback",
    name: "Give Feedback",
    rtlName: "لوحة القيادة",
    icon: Feedback,
    component: GiveFeedback,
    layout: "/user"
  },
  {
    path: "/ratefeedback",
    name: "Rate/View Feedback",
    rtlName: "لوحة القيادة",
    icon: Star,
    component: RateFeedback,
    layout: "/user"
  },
  /*
  {
    path: "/peerevaluation",
    name: "Peer Evaluation",
    rtlName: "ملف تعريفي للمستخدم",
    icon: People,
    component: PeerEvaluation,
    layout: "/user"
  },*/
  {
    path: "/changepassword",
    name: "Change Password",
    rtlName: "لوحة القيادة",
    icon: Key,
    component: ChangePassowrd,
    layout: "/user"
  },
  {
    path: "/logout",
    name: "Logout",
    rtlName: "لوحة القيادة",
    icon: Exit,
    component: Logout,
    layout: "/user"
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: AdminDashboard,
    layout: "/admin"
  },
  {
    path: "/givefeedback",
    name: "Give feedback",
    rtlName: "لوحة القيادة",
    icon: Feedback,
    component: AdminGiveFB,
    layout: "/admin"
  },
    {
    path: "/studentInfo",
    name: "Student Info",
    rtlName: "لوحة القيادة",
    icon: FaceIcon,
    component: AdminStudentInfo,
    layout: "/admin"
  },
   {
    path: "/changepassword",
    name: "Change Password",
    rtlName: "لوحة القيادة",
    icon: Key,
    component: ChangePassowrd,
    layout: "/admin"
  }
  /*
  {
    path: "/designform",
    name: "Design form",
    rtlName: "لوحة القيادة",
    icon: Star,
    component: AdminForm,
    layout: "/admin"
  }*/
];

export default dashboardRoutes;
