import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Tasks from "components/Tasks/Tasks.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Danger from "components/Typography/Danger.jsx";
import Info from "components/Typography/Info.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "components/CustomButtons/Button.jsx";
import TextField from '@material-ui/core/TextField';
import { bugs, website, server } from "variables/general.jsx";
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import endpoint from '../variables/endPoint.js'
import { SnackbarProvider, withSnackbar } from 'notistack';
import beautifyDate from '../variables/date.js'



class InsProjectGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      memberNames: "",
      insFeedback:"",
      refreshPage: false,
      isSubmitting: false,
      feedback:"",
      isEditingFB: false
    };

    this.handleCommentsChange = this.handleCommentsChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleEditFB = this.handleEditFB.bind(this)
  }

  processMemberInfo(){
    var memberObject = ''
    if(this.props.projectInfo.members.length != 0)
      memberObject = JSON.parse(this.props.projectInfo.members)
    else
      memberObject = []

    var names = "";
    memberObject.member_info.map(member => (
        names += member.name+'\xa0\xa0\xa0'
      ))
    this.setState({
      memberNames: names
    })
    return memberObject
  }

  componentDidMount(){
    var memberObject = this.processMemberInfo();
  }


  handleCommentsChange(e){
    console.log(e.target.value)
    this.setState({
      feedback:e.target.value
    })
  }

  handleSubmit(){
    this.setState({
      isSubmitting: true
    })

    fetch( endpoint +"/admin/uploadfeedback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        project_group_id: this.props.projectInfo.project_group_id,
        feedback: this.state.feedback
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
          this.props.enqueueSnackbar('Submission failed', { variant: 'warning' });
          this.setState({
            isSubmitting: false
          })
        } else {
          this.props.enqueueSnackbar('Your feedback is submitted!', { variant: 'success' });
          this.setState({
            isSubmitting: false
          })
         this.props.refreshPage(this.props.projectInfo.course_id);
        }
      });
  }

  handleEditFB(){
    this.setState({
      isEditingFB: true
    })
  }

  render() {
    const { classes } = this.props;
    const projectInfo = this.props.projectInfo

    const insFeedbackBox = (projectInfo.ins_feedback != null && !this.state.isEditingFB)?projectInfo.ins_feedback
    :<TextField
      id="outlined-textarea"
      label = "Your comments"
      placeholder="Placeholder"
      multiline
      className={classes.textField}
      margin="normal"
      variant="outlined"
      fullWidth
      onChange={this.handleCommentsChange}
      defaultValue={projectInfo.ins_feedback}
    />

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite}>{projectInfo.project_name}</h4>
                <p className={classes.cardCategoryWhite}>
                  {projectInfo.group_name}&emsp;&emsp;Presentation Date: {beautifyDate(projectInfo.presen_date)}
                </p>
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardCategory}>Group Members</h4>
                <p className={classes.description}>
                  {this.state.memberNames}
                </p>
                <h4 className={classes.cardCategory}>Project Description</h4>
                <p className={classes.description}>
                  {projectInfo.project_description}
                </p>
                <h4 className={classes.cardCategory}>Instructor Feedback</h4>
                <p className={classes.description}>
                  {insFeedbackBox}
                </p>
              </CardBody>
              <CardFooter>
                {(projectInfo.ins_feedback == null || this.state.isEditingFB)?<Button color="warn" disabled={this.state.isSubmitting} onClick={this.handleSubmit}>Submit Feedback</Button>:
                <Button color="warn" onClick={this.handleEditFB}>Edit Feedback</Button>}
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}


InsProjectGrid.propTypes = {
  classes: PropTypes.object.isRequired
};

const ins = withSnackbar(InsProjectGrid);
export default withStyles(dashboardStyle)(ins);
