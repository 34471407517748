import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import FeedbackGrid from "components/FeedbackGrid.jsx";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Success from "components/Typography/Success.jsx"
import Button from '@material-ui/core/Button';
import { SnackbarProvider, withSnackbar } from 'notistack';
import CircularProgress from "components/CircularProgress.jsx"
//api url
import endpoint from 'variables/endPoint.js'


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  button:{

  }
};


class RateFeedback extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      allFeedbacksInfo: [],
      feedbacksInfoIsLoaded: false,
      haveUnratedFeedback: true,
      pageCount: [],
      pageButState:[],
      modeButState: [true, false],
      mode: 0
    };
    this.loadFeedbacksInfo = this.loadFeedbacksInfo.bind(this)
    this.loadMoreFBWhenScroll = this.loadMoreFBWhenScroll.bind(this)
    this.loadFeedbacksInfo(0)
  }

  processfeedbackInfo(data){
    var fb_qindex = this.props.userInfo.rate_fb_qindex
    var finalFeedback = data.feedbacks.map( function(feedback){
      feedback.feedback_content = (JSON.parse(feedback.feedback_content)).questions[fb_qindex].value
      return feedback
    })
    var pageButGroup = []
    for(let i = 0; i < data.pageCount; i++){
      if(i == 0)
        pageButGroup.push(true)
      else
        pageButGroup.push(false)
    }
    this.setState({
      allFeedbacksInfo: finalFeedback,
      haveUnratedFeedback: data.haveUnratedFeedback,
      pageCount: Array.apply(null, {length: data.pageCount}).map(Number.call, Number),
      pageButState:pageButGroup,
      feedbacksInfoIsLoaded: true
    })
  }
  getNewBatch(data){
    this.setState({
      feedbacksInfoIsLoaded: false
    })
    this.updateNewBatchFeedbackInfo(data)
  }

  updateNewBatchFeedbackInfo(data){
    var fb_qindex = this.props.userInfo.rate_fb_qindex
    var finalFeedback = data.feedbacks.map( function(feedback){
      feedback.feedback_content = (JSON.parse(feedback.feedback_content)).questions[fb_qindex].value
      return feedback
    })
    this.setState({
      feedbacksInfoIsLoaded: true,
      allFeedbacksInfo: finalFeedback
    })
  }

  loadFeedbacksInfo(mode){
    fetch( endpoint +"/feedback/getunratedfeedback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user_id: this.props.userInfo.user_id,
        project_group_id: this.props.userInfo.project_group_id,
        mode: mode
      })
    })
      .then(res => res.json())
      .then(res => {
        //console.log("res: ", res.err);
        if (res.err) {
          console.log("error", res.err)
          this.setState({
              feedbacksInfoIsLoaded: true
            })
        } else {
          //projects info is back
          this.processfeedbackInfo(res.data)
        }
      });
  }

  handleModeChange(mode){
    let newModState = [false, false]
    newModState[mode] = true;
    this.setState({
      modeButState: newModState,
      mode: mode,
      feedbacksInfoIsLoaded: false
    })
    this.loadFeedbacksInfo(mode)
  }

  loadMoreFBWhenScroll(e){
    var newButState=[]
    for(let i=0; i<this.state.pageButState.length;i++)
      newButState.push(false)
    newButState[e.target.innerHTML - 1] = true;
    this.setState({
      pageButState: newButState
    })

    fetch( endpoint +"/feedback/getfeedbackbatch", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        page_num: e.target.innerHTML,
        user_id: this.props.userInfo.user_id,
        project_group_id: this.props.userInfo.project_group_id,
        mode: this.state.mode
      })
    })
      .then(res => res.json())
      .then(res => {
        //console.log("res: ", res.err);
        if (res.err) {
        } else {
          //projects info is back
          this.getNewBatch(res.data)
        }
      });
  }

  render(){
    const { classes } = this.props;
    var user = this.props.userInfo
    var refreshPage = this.loadFeedbacksInfo
    var haveUnratedFeedback = this.state.haveUnratedFeedback
    var loadMoreFB = this.loadMoreFBWhenScroll
    var pageButState = this.state.pageButState

    var ratingStatus = <div>
                        <Button className={classes.button} disabled={this.state.modeButState[0]} onClick={() => this.handleModeChange(0)}>Sort by my rating</Button>
                        <Button className={classes.button} disabled={this.state.modeButState[1]} onClick={() => this.handleModeChange(1)}>Sort by group average rating</Button>
                      </div>;

    console.log("RateFeedback: ", this.state.allFeedbacksInfo.length)

    var feedbacks = this.state.allFeedbacksInfo.map( function(fb){
          return <FeedbackGrid
            feedbackInfo={fb}
            userInfo={user}
            refreshPage={refreshPage}
            key = {fb.from_user}
            isUnrated = {haveUnratedFeedback}
          />
      });
    var buttonGroup = this.state.pageCount.map( function(index){
      return <Button disabled={pageButState[index]} key={index} className={classes.button} onClick={loadMoreFB}>{index+1}</Button>

    });

    return (
      <div>
        {
          (this.state.allFeedbacksInfo.length == 0)?<div>No feedback to be rated</div>:""
        }
        {!this.state.feedbacksInfoIsLoaded?(<div style={{marginTop: "20%", marginLeft: "40%"}}>
              <CircularProgress/>
            </div>): 
          (<div>
            {(!this.state.haveUnratedFeedback)?ratingStatus:""}
            <SnackbarProvider maxSnack={3}>
              {feedbacks}
            </SnackbarProvider>
            {(!this.state.haveUnratedFeedback)?buttonGroup:""}
          </div>)
        }
      </div>

    );
  }
}

export default withStyles(styles)(RateFeedback);
