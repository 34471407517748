import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import CourseList from "components/CourseList.jsx";
import Table from "components/Table/Table.jsx";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
//api url
import endpoint from 'variables/endPoint.js'

class Forms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curSemester: props.curSemester,
      iHaveRatedAll: -1,
    };
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidUpdate(prevProps) {
  //  this.fetchData();
  }

  componentDidMount(){
    //this.fetchData();
  }

  handleChange = (event) => {
    this.setState({ semester: event.target.value });
  };

  fetchData(){
    fetch( endpoint +"/user/getdashboardnum", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user_id: this.props.userInfo.user_id,
        projectInfo: this.props.myProjectInfo,
      })
    })
      .then((res) => {if (res.ok) {
            return res.json();
          } else {
            throw new Error('Something went wrong');
          }})
      .then(res => {
        if (res.err) {
        } else {
          //projects info is back
          var length = 0;
          if(this.props.myProjectInfo.members.length == 0)
            length = 0
          else length = JSON.parse(this.props.myProjectInfo.members).length-1

          let data = {
            "fb_to_give": res.data[0]['count'],
            "fb_given": res.data[1]['count'],
            "fb_received": res.data[2]['count'],
            "fb_rated": res.data[3]['count'],
            //"peer_to_give": length,
            //"peer_given": res.data[4]['count']
          }
          let showInsFeedback = false;
          if((data.fb_received - data.fb_rated) == 0 && (data.fb_to_give-data.fb_given)==0)
            showInsFeedback =true
          else
            showInsFeedback = false
          this.setState({
            numData: data,
            numIsLoaded:true,
            iHaveRatedAll: showInsFeedback
          })
        }
      }).catch(console.error);
  }

  render() {
    return (
      <div>
        <CourseList courseInfo={this.props.courseInfo} loadProjectsInfo={()=>{}}/>
      </div>
    );
  }
}



Forms.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Forms);
