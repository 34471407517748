import React from "react";
import SHA from 'sha1'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import Button from "components/CustomButtons/Button.jsx";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Danger from "components/Typography/Danger.jsx";
//api url
import endpoint from 'variables/endPoint.js'



const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});




class ChangePassword extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      curPwd:"",
      newPwd:"",
      refresh: true, //for page refresh after successfully change pwd
      pwdMsg: "",
      pwdIsChanged: false,
      isConfirming: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleSubmit(){
    let user_id = this.props.userInfo.user_id
    if(this.state.curPwd.length == 0 || this.state.newPwd.length == 0)
      this.setState({
        pwdMsg:"Cannot be empty"
      })
    else if(this.state.newPwd.length < 8 )
      this.setState({
        pwdMsg:"Your new password should have at least 8 characters!"
      })
    else{
      this.setState({
        isConfirming:true
      })
      fetch( endpoint +"/user/changepassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          userid: user_id,
          curPwd: SHA(this.state.curPwd),
          newPwd: SHA(this.state.newPwd)
        })
      })
        .then(res => res.json())
        .then(res => {
          if (res.err) {
            this.setState({
              pwdMsg:"Your password is incorrect",
              isConfirming: false
            })
          } else {
            this.setState({
              curPwd:"",
              newPwd:"",
              pwdIsChanged: true,
              isConfirming: false
            })
          }
        });
    }
  }

  handleInputChange(e){
    let gg;
    let name = e.target.name
    this.setState({
      [name]: e.target.value
    })
  }


  render(){
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
        <Card>
          {(!this.state.pwdIsChanged)?(
          <CardBody>
            <FormControl margin="normal"  required fullWidth>
              <InputLabel htmlFor="curPwd">Input your current password</InputLabel>
              <Input id="curPwd" name="curPwd" type="password" onChange={this.handleInputChange}/>
            </FormControl>
            <FormControl margin="normal"  required fullWidth>
              <InputLabel htmlFor="newPwd">Input your new password</InputLabel>
              <Input name="newPwd" type="password" id="newPwd" onChange={this.handleInputChange}/>
            </FormControl>
            <Danger className={classes.cardTitle}>{this.state.pwdMsg}</Danger>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.handleSubmit}
              disabled={this.state.isConfirming}
            >
              {(this.state.isConfirming)?"Loading...":"Confirm"}
            </Button>
          </CardBody>):<CardBody>You password has been changed successfully!</CardBody>}
        </Card>
      </GridItem>
    </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(ChangePassword);
