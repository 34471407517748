
import React from 'react';
import { Switch, Route} from 'react-router-dom';
import UserDashboard from "layouts/User.jsx";
import AdminDashboard from "layouts/Admin.jsx";
import Login from "layouts/Login.jsx";
import Forget from "layouts/Forget.jsx";
import "assets/css/material-dashboard-react.css?v=1.6.0";
import { CookiesProvider } from 'react-cookie';



const App = () => (
  <CookiesProvider>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/user" component={UserDashboard} />
        <Route path="/admin" component={AdminDashboard} />
        <Route path="/forget" component={Forget} />
      </Switch>
  </CookiesProvider>
);


export default App;
