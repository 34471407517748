import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import CourseList from "components/CourseList.jsx";
import Table from "components/Table/Table.jsx";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { SnackbarProvider, withSnackbar } from 'notistack';
import CircularProgress from "components/CircularProgress.jsx"
import InsProjectGrid from "components/InsProjectGrid.jsx"

//api url
import endpoint from 'variables/endPoint.js'

class GiveFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectsInfo:[],
      projectsInfoIsLoaded: false,
      courseInfo: this.props.courseInfo
    };
    this.handleChange = this.handleChange.bind(this)
    this.loadProjectsInfo = this.loadProjectsInfo.bind(this)
  }

  componentDidUpdate(prevProps) {
  //  this.fetchData();
    if(prevProps.courseInfo != this.props.courseInfo){
      this.setState({
        courseInfo: this.props.courseInfo
      })
    }
  }

  componentDidMount(){
    console.log("GiveFeedback: componentDidMount")
    this.loadCourseInfo()
  }

  handleChange = (event) => {
    this.setState({ semester: event.target.value });
  };

  loadCourseInfo = (userInfo) =>{
    fetch( endpoint +"/admin/getCourseInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        semester: this.props.curSemester
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
        } else {
          console.log("GiveFeedback loadCourseInfo: ", res.data)
            this.setState({
              courseInfo: res.data
            })
          if(res.data.length == 0){
             this.setState({
                projectsInfo: [],
                projectsInfoIsLoaded: true
              })
             return
          }

          this.loadProjectsInfo(this.state.courseInfo[0].course_id);
        }
      });
  }


  loadProjectsInfo(cid){

    fetch( endpoint +"/admin/getProjectsInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        course_id: cid,
        section: this.props.userInfo.section,
        user_id: this.props.userInfo.user_id
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
          this.setState({
            projectsInfo: [],
            projectsInfoIsLoaded: true
          })
        } else {
          //projects info is back
          this.setState({
            projectsInfo: res.data.projectsInfo,
            projectsInfoIsLoaded: true
          })
        }
      });
  }

  render() {
    const { classes } = this.props;
    var refreshPage = this.loadProjectsInfo
    var projects = this.state.projectsInfo.map( function(project){
          return <InsProjectGrid
            projectInfo={project}
            refreshPage={refreshPage}
            key = {project.project_group_id}
          />
      });

    return (
      <div>
        <CourseList courseInfo={this.state.courseInfo} loadProjectsInfo={this.loadProjectsInfo}/>
          {(this.state.courseInfo.length == 0)?"No Course in this semester":""}
          <SnackbarProvider maxSnack={3}>
          {this.state.projectsInfoIsLoaded?projects:<CircularProgress/>}
            </SnackbarProvider>
      </div>
    );
  }
}



GiveFeedback.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(GiveFeedback);
