import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import StuInfoTable from "components/StuInfoTable/StuInfoTable.jsx";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Danger from "components/Typography/Danger.jsx";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from "components/CircularProgress.jsx"


//api url
import endpoint from 'variables/endPoint.js'



const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  semBtns:{
    display: "flex",
    flexDirection: "row"
      }
});

class StudentInfo extends React.Component {
  constructor(props) {
    super(props);

    var curCourseID = -1
    if(this.props.courseInfo.length != 0){
      curCourseID = this.props.courseInfo[0].course_id
    }

    this.state = {
      courseInfo:this.props.courseInfo,
      curCourseID: curCourseID,
      studentsInfo: [],
      isLoading: false
    };

    this.handleCourseChange = this.handleCourseChange.bind(this)
  }

  componentDidUpdate(prevProps) {
  //  this.fetchData();
    if(prevProps.courseInfo != this.props.courseInfo){
        this.setState({
          courseInfo: this.props.courseInfo
        })
      }
  }

  componentDidMount(){
    console.log("StudentInfo: componentDidMount", this.props.courseInfo)
    this.loadCourseInfo()
  }


  loadCourseInfo = (userInfo) =>{
    fetch( endpoint +"/admin/getCourseInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        semester: this.props.curSemester
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
        } else {
          console.log("GiveFeedback loadCourseInfo: ", res.data)

          if(res.data.length == 0){
             this.setState({
                studentsInfo: [],
                courseInfo: [],
                isLoading: false
              })
             return
          }

            this.setState({
              courseInfo: res.data,
              curCourseID: this.props.courseInfo[0].course_id
            })

          this.loadStudentsInfo(this.props.courseInfo[0].course_id);
        }
      });
  }


  handleCourseChange = (event) => {
    console.log(event.target.value)
    this.setState({ curCourseID: event.target.value, isLoading:true });
    this.loadStudentsInfo(event.target.value)
  };

  handleSemCreateOpen = (event) => {
    this.setState({ openSem: true });
  };

  handleCourseCreateOpen = (event) => {
    this.setState({ openCourse: true });
  };

  loadStudentsInfo = (course_id) =>{
    console.log("StudentInfo loadStudentsInfo: ", course_id)
    fetch( endpoint +"/admin/getStudentInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        course_id: course_id
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
        } else {
            this.setState({
              studentsInfo: res.data,
              isLoading: false
            })
            console.log("studentsInfo: ", res.data)
        }
      });
  }

  loadStudentsInfoDefault = () =>{
    console.log("StudentInfo loadStudentsInfo: ", this.state.curCourseID)
    fetch( endpoint +"/admin/getStudentInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        course_id: this.state.curCourseID
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
        } else {
            this.setState({
              studentsInfo: res.data,
              isLoading: false
            })
            console.log("studentsInfo: ", res.data)
        }
      });
  }

  handleClose(){

  }


  render() {

    const { classes } = this.props;

    const courseInfo = this.state.courseInfo
    console.log("render: ", this.state.studentsInfo)
    return (
      <div>
          <Select
          value={this.state.curCourseID}
          onChange={this.handleCourseChange}
          displayEmpty
          name="age"
          >
            {courseInfo.map((option, index) => (
              <MenuItem
                key={index}
                value={option.course_id}
              >
                {option.course_code+" "+option.section}
              </MenuItem>
            ))}
          </Select>
        {(this.state.isLoading)?(<CircularProgress/>):(<StuInfoTable updateStudentsInfo={this.loadStudentsInfoDefault} studentsInfo={this.state.studentsInfo}></StuInfoTable>)}
      </div>
    );
  }
}



StudentInfo.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(StudentInfo);
