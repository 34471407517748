import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import endpoint from '../../variables/endPoint.js'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Danger from "components/Typography/Danger.jsx";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { SnackbarProvider, withSnackbar } from 'notistack';


const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);



const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
   paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  semBtns:{
    display: "flex",
    flexDirection: "row"
      }
});


class CustomizedTables extends React.Component {

  constructor(props){
    super(props)
    this.state={
      studentsInfo: this.props.studentsInfo,
      groupInfo: [],
      openGroupBox: false,
      curUser: {},
      curUserNewGroupID: 0,
      isLoading: false
    }

  }

  generateGroupMap(studentsInfo){
    var groupInfoMap = new Map()
    for(let i = 0; i < studentsInfo.length; i++){
      groupInfoMap.set(studentsInfo[i].group_name, studentsInfo[i].project_group_id)
    }
    groupInfoMap.set("Inactive Group", -1)
    var groupArr = Array.from(groupInfoMap, ([name, value]) => ({ name, value }))
    this.setState({
      groupInfo: groupArr
    })
  }

  componentDidUpdate(prevProps){
    console.log("StuInfoTable: componentDidUpdate")
    if(JSON.stringify(prevProps.studentsInfo) != JSON.stringify(this.props.studentsInfo)){
      this.generateGroupMap(this.props.studentsInfo)
      this.setState({
        studentsInfo: this.props.studentsInfo
      })
    }
  }

  componentDidMount(){
    console.log("StuInfoTable: componentDidMount")
    this.generateGroupMap(this.props.studentsInfo)
  }

  handleGroupChange(val){
    if(val == 0){
 
    }
    else{
      //import data
    //  console.log("delete course: ", this.state.courseToDelete)
      fetch( endpoint +"/admin/switchGroup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
          "user_info": this.state.curUser,
          "new_group_id": this.state.curUserNewGroupID
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
         console.log(res.err)
        this.props.enqueueSnackbar('Fail to change group', { variant: 'warning' })
        } else {
          this.props.enqueueSnackbar('Group is changed!', { variant: 'success' });
          this.props.updateStudentsInfo()
        }
      });
    }
    this.setState({openGroupBox: false})

  };

  openGroupDialog = (event) => {
      console.log(event.target.value, event.target.name)
      this.setState({
        openGroupBox: true,
        curUser: event.target.name,
        curUserNewGroupID: event.target.value
      })
      
    // this.setState({ curCourseID: event.target.value, isLoading:true });
    // this.loadStudentsInfo()
  };

  groupIDtoName(id){
    var groups = this.state.groupInfo
    for(let i = 0; i < groups.length; i++){
      if(groups[i]["value"] == id)
        return groups[i]["name"]
    }
  }


 

  render(){
      const { classes } = this.props;

      const bodyGroup = (
        <div style={{backgroundColor:"white"}}>
          <div className={classes.paper}>
              <div style={{'fontSize': '16px'}}>Are you sure you want to move {this.state.curUser["name"]} to {this.groupIDtoName(this.state.curUserNewGroupID)}?</div>   
              <div className={classes.semBtns}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submit}
                  onClick={() => this.handleGroupChange(0)}
                  disabled={this.state.isLoading}
                  style={{marginRight:"10%"}}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => this.handleGroupChange(1)}
                  disabled={this.state.isLoading}
                >
                  Confirm
                </Button>
              </div>
          </div>
        </div>
      );

      var rows = []
      if(this.state.studentsInfo!=undefined)
        rows = this.state.studentsInfo

    console.log("StuInfoTable render****: ", this.state.groupInfo)
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Group</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell>{row.email}</StyledTableCell>
                <StyledTableCell>
                  <Select
                    value={row.project_group_id}
                    name={row}
                    onChange={this.openGroupDialog}
                    displayEmpty
                  >
                    {this.state.groupInfo.map((obj, index) => {
                      return <MenuItem value={obj.value}>{obj.name}</MenuItem>
                    })}
                  </Select>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <Modal
            open={this.state.openGroupBox}
            onClose={this.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{top:"20%", left:"50%", width:"300px"}} 
          >
            {bodyGroup}
          </Modal>
      </Paper>
    );
  }
}

const ins = withSnackbar(CustomizedTables);
export default withStyles(styles)(ins);
