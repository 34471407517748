import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import feedbackIcon from "@material-ui/icons/Feedback";
import infoIcon from "@material-ui/icons/Info";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Tasks from "components/Tasks/Tasks.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Danger from "components/Typography/Danger.jsx";
import Info from "components/Typography/Info.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "components/CustomButtons/Button.jsx";
import RatingPicker from "components/RatingPicker.jsx";
import TextField from '@material-ui/core/TextField';
import endpoint from '../variables/endPoint.js'
import { SnackbarProvider, withSnackbar } from 'notistack';


import { bugs, website, server } from "variables/general.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import beautifyDate from '../variables/date.js'

var feedbackStatus = Object.freeze({"isGiven":1, "notGiven":2, "noNeedToGive":3})


class OthersProjectGrid extends React.Component {
  constructor(props) {
    super(props);
    //var memberObject = JSON.parse(this.props.projectInfo.members)
    this.state = {
      value: 0,
      //projectMemberNames: [],
      havePresented: false,
      feedbackData:this.props.fbDataTemplate,
      isSubmitting: false,
      canEdit: false,
      editLastDateString: "",
      showFBFormWarning: false
     // memberList:memberObject.member_info.map(member => (member.name+'\xa0\xa0\xa0\xa0\xa0'))
    };

    this.handleRateChange = this.handleRateChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCommentsChange = this.handleCommentsChange.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.setCurFbData = this.setCurFbData.bind(this)

  }

  componentDidMount(){
    this.checkIfPresented(this.props.projectInfo.presen_date)
    this.checkIfCanEdit(this.props.projectInfo.presen_date)
    if(this.props.projectInfo.feedbackStatus == feedbackStatus.isGiven)
      this.setCurFbData()
  }

  setCurFbData(){
    var feedback = JSON.parse(JSON.stringify(this.state.feedbackData));
    var  myFeedback = JSON.parse(this.props.projectInfo.myFeedback);

     this.props.userInfo.fb_form.questions.map(function(question, index){
      feedback.questions[index].value = myFeedback.questions[index].value
     })
    this.setState({
      feedbackData: feedback
    })
  }

  toHKTime(date){
    var timezone = 8
    var offset_GMT = date.getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
    var nowDate = date.getTime(); // 本地时间距 1970 年 1 月 1 日午夜（GMT 时间）之间的毫秒数
    var targetDate = new Date(nowDate + offset_GMT * 60 * 1000 + timezone * 60 * 60 * 1000);
    return targetDate
  }

  checkIfCanEdit(presen_date){
    let curDate = new Date()
    //let presenDate = new Date(presen_date)
    let editLastDate = new Date(presen_date.replace(/-/g, "/")) //changed for safari compatibility
    
    
    editLastDate.setHours(23)
    editLastDate.setMinutes(59)
    editLastDate.setDate(editLastDate.getDate()+this.props.courseInfo.fb_ddl);

    curDate = this.toHKTime(curDate)
    //editLastDate = this.toHKTime(editLastDate)
    console.log("checkIfCanEdit - presen date: ", presen_date, "cur date: ", curDate, "ddl: ", editLastDate, " length of fb days: ", this.props.courseInfo.fb_ddl)

   // console.log(this.props.projectInfo.group_name, "curDate: ",curDate, " editLastDate: ", editLastDate)
    var editLastDateString = beautifyDate(editLastDate.getFullYear()+"-"+(editLastDate.getMonth()+1)+"-"+editLastDate.getDate())
    
    if(curDate-editLastDate <= 0)
      this.setState({
        canEdit: true,
        editLastDateString: editLastDateString
      })
    else
      this.setState({
        canEdit: false,
        editLastDateString: editLastDateString
      })
  }

  checkIfPresented(presen_date){
    let curDate = new Date()
    let presenDate = new Date(presen_date.replace(/-/g, "/")) //changed for safari compatibility

    curDate = this.toHKTime(curDate)

    console.log(this.props.projectInfo.group_name, "curDate: ",curDate, " presenDate: ", presenDate)

    if(presenDate - curDate <= 0)
      this.setState({
        havePresented: true
      })
    else
      this.setState({
        havePresented: false
      })
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  handleRateChange(index, answer){
    var feedback = JSON.parse(JSON.stringify(this.state.feedbackData));
    feedback.questions[index].value = answer;
    this.setState({
      feedbackData:feedback
    })
  }

  handleCommentsChange(index, e){
    //this.handleAnswerChange(3, e.target.value)
    var feedback = JSON.parse(JSON.stringify(this.state.feedbackData));
    feedback.questions[index].value = e.target.value;
    this.setState({
      feedbackData:feedback
    })
  }

  checkFormValid(fbdata){
    var questions = fbdata["questions"]
    for(let i = 0; i < questions.length; i++){
      if(questions[i]["type"] == "rating" && questions[i]["value"] == -1){
        this.setState({
          showFBFormWarning: true
        })
        return false
      }
      else if(questions[i]["type"] == "comments" && questions[i]["value"] == ""){
        this.setState({
          showFBFormWarning: true
        })
        return false
      }
    }
    return true

  }


  handleSubmit(){

    let fbdata = this.state.feedbackData
    if(!this.checkFormValid(fbdata))
      return

    this.setState({
      isSubmitting: true
    })

    /*for(let i=0; i<fbdata.questions.length; i++){
      console.log(i)
      if(fbdata.questions[i].type == "rating" && fbdata.questions[i].value==""){
        fbdata.questions[i].value = "0"
        console.log("set = 0")
      }
    }*/
    fetch( endpoint +"/feedback/uploadfeedback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        from_user: this.props.userInfo.user_id,
        to_project_group: this.props.projectInfo.project_group_id,
        feedback_content: JSON.stringify(fbdata)
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
          this.props.enqueueSnackbar('Submission failed', { variant: 'warning' });
          this.setState({
            isSubmitting: false
          })
        } else {
          this.props.enqueueSnackbar('Your feedback is submitted!', { variant: 'success' });
          this.setState({
            isSubmitting: false
          })
          this.props.refreshPage();
          //  this.checkIfCanEdit(this.props.projectInfo.presen_date)
        }
      });
  }

  handleUpdate(){
    this.setState({
      isSubmitting: true
    })
    let fbdata = this.state.feedbackData

    fetch( endpoint +"/feedback/updatefeedback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        from_user: this.props.userInfo.user_id,
        to_project_group: this.props.projectInfo.project_group_id,
        feedback_content: JSON.stringify(fbdata)
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
          this.props.enqueueSnackbar('Submission failed', { variant: 'warning' });
          this.setState({
            isSubmitting: false
          })
        } else {
          this.setState({
            isSubmitting: false
          })
          this.props.enqueueSnackbar('Your feedback is updated!', { variant: 'success' });
         this.props.refreshPage();
        }
      });
  }

  render() {
    const { classes } = this.props;
  //  const projectInfo = this.props.projectInfo
    var projectInfo = this.props.projectInfo
    var feedbackPage;
    var myFbIndex = this.props.userInfo.rate_fb_qindex
        var tabArray = [
          {
            tabName: "Info",
            tabIcon: infoIcon,
            tabContent: (
              <GridItem xs={12} sm={12} md={12}>
                  <CardBody>
                    <h4 className={classes.cardCategory}>Group Name</h4>
                    <p className={classes.description}>
                      {projectInfo.group_name}
                    </p>
                    <div hidden={this.props.courseInfo.show_presen_date == 0}>
                      <h4 className={classes.cardCategory}>Presentation Date</h4>
                      <p className={classes.description}>
                        {beautifyDate(projectInfo.presen_date)}
                      </p>
                    </div>
                    <h4 className={classes.cardCategory}>Project Description</h4>
                    <p className={classes.description}>
                      {projectInfo.project_description}
                    </p>
                    {(projectInfo.feedbackStatus == feedbackStatus.isGiven)?<div><h4 className={classes.cardCategory}>Your Feedback</h4>
                      <p className={classes.description}>
                        { JSON.parse(projectInfo.myFeedback).questions[myFbIndex].value}
                      </p></div>:""}
                  </CardBody>
              </GridItem>
            )
          }]

    if(!this.state.havePresented){
      //don't give the feedback page if the group hasn't presented yet
    }
    else if(projectInfo.feedbackStatus == feedbackStatus.isGiven){

      var handleCommentsChange = this.handleCommentsChange
      var handleRateChange = this.handleRateChange
      var canEdit = this.state.canEdit
      var  myFeedback = JSON.parse(projectInfo.myFeedback);

      var cardBody = this.props.userInfo.fb_form.questions.map(function(question, index){
        if(question.type == "comments"){
         // handleCommentsChange(index, myFeedback.questions[index].value)
          return(
            <div>
            <h4 className={classes.cardCategory}>{question.description}</h4>
              <TextField
                id="outlined-textarea"
                label = "Your comments"
                placeholder="Placeholder"
                defaultValue={myFeedback.questions[index].value}
                multiline
                className={classes.textField}
                margin="normal"
                variant="outlined"
                disabled = {!canEdit}
                fullWidth
                onChange={(e) => handleCommentsChange(index, e)}
              />
            </div>
          )
        }
        else {
          return(
          <div>
            <h4 className={classes.cardCategory}>{question.description}</h4>
            <RatingPicker index={index} disabled = {!canEdit} handlePicking={handleRateChange} values={question.values} selectedIndex={parseInt(myFeedback.questions[index].value)}/>
          </div>
          )
        }
      })


      feedbackPage =
        {
          tabName: "View Feedback",
          tabIcon: feedbackIcon,
          tabContent: (
          <div>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              {cardBody}
            </GridItem>
          </GridContainer>
          <CardFooter>
            <Button color="primary" disabled={this.state.isSubmitting || !this.state.canEdit} onClick={this.handleUpdate}>Update Feedback</Button>
          </CardFooter>
        </div>
        )
      }
      tabArray.push(feedbackPage);
    }
    else if(projectInfo.feedbackStatus == feedbackStatus.notGiven){
      var handleCommentsChange = this.handleCommentsChange
      var handleRateChange = this.handleRateChange
      var canEdit = this.state.canEdit
      var cardBody = this.props.userInfo.fb_form.questions.map(function(question, index){

        if(question.type == "comments")
          return(
            <div>
            <h4 className={classes.cardCategory}>{question.description}</h4>
              <TextField
                id="outlined-textarea"
                label = "Your comments"
                placeholder="Placeholder"
                multiline
                disabled = {!canEdit}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                fullWidth
                onChange={(e) => handleCommentsChange(index, e)}
              />
            </div>
          )
        else return(
          <div>
            <h4 className={classes.cardCategory}>{question.description}</h4>
            <RatingPicker index={index} disabled = {!canEdit} handlePicking={handleRateChange} values={question.values}/>
          </div>
      )
      })

      feedbackPage =
        {
          tabName: "Give Feedback",
          tabIcon: feedbackIcon,
          tabContent: (
          <div>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              {cardBody}
            </GridItem>
          </GridContainer>
          <div hidden={!this.state.showFBFormWarning}>
            <Danger>Your must answer all questions.</Danger>
          </div>
          <CardFooter>
            <Button color="primary" disabled={this.state.isSubmitting|| !canEdit} onClick={this.handleSubmit}>Submit Feedback</Button>
          </CardFooter>
        </div>
        )
      }
      tabArray.push(feedbackPage);
    }
    else{
      //no need to give feedback to this group, do nothing to the array
    }
/*
    if(tabArray.length == 2){
      var temp = tabArray[1]
      tabArray[1] = tabArray[0]
      tabArray[0] = temp
    }*/

    return (
      <CustomTabs
        title={projectInfo.project_name}
        feedbackStatus={projectInfo.feedbackStatus}
        havePresented={this.state.havePresented}
        canEdit={this.state.canEdit}
        editLastDate={this.state.editLastDateString}
        headerColor="primary"
        tabs={tabArray}
        style={{margin: 200}}
      />
    );
  }
}

OthersProjectGrid.propTypes = {
  classes: PropTypes.object.isRequired
};

const MyApp = withSnackbar(OthersProjectGrid);

export default withStyles(dashboardStyle)(MyApp);
