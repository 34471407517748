import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import Radio from '@material-ui/core/Radio';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Button from "components/CustomButtons/Button.jsx";


const styles = {
  root: {
    color: green[600],
    '&$checked': {
      color: green[500],
    },
  },
  checked: {},
  checkbox:{
    marginRight: 10
  }
};

class RadioButtons extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: (this.props.selectedIndex != undefined)?this.props.selectedIndex:-1
    };
  }

  handleChange = event => {
    this.setState({ selectedIndex: event.target.value });
    this.props.handlePicking(this.props.index, event.target.value); //modified
  };

  render() {

    const { classes } = this.props;
    var selectedIndex = this.state.selectedIndex
    var handleChange = this.handleChange
    var disabled = this.props.disabled

    var choices = this.props.values.map(function(value,index){
      return(
        <div>
          <Radio className = "checkbox"
            checked={selectedIndex == index}
            onChange={handleChange}
            value={index}
            name="radio-button-demo"
            disabled={disabled}
            aria-label={1}
            key={index}
          />{value}
        </div>
      )
    })

    return (
      <div>
        {choices}
      </div>
    );
  }
}

RadioButtons.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RadioButtons);
